import { utils, Enum } from '@utocat/catalizr-const-sharing-lib';

import { getStore } from '../../store';
import { isNotBlank } from './CatalizrUtils';

export const JOURNEY = {
  // When user bank prefilled operation
  BANK_COMPLETE: 'bank-complete',
  // When user bank advance operation at 20% to 40% - simulate investors rights in this mode
  BANK_FORWARD_INVESTOR: 'bank-forward-investor',
  // when user bank advance operation at 60% to 95% - simulate company rights in this mode
  BANK_FORWARD_COMPANY_2: 'bank-forward-company-2',
  // When user bank create operation template
  BANK_TEMPLATE: 'bank-template',
  // When user company create operation template
  COMPANY_TEMPLATE: 'company-template',
  // ??
  BANK_TRANSMIT: 'bank-transmit',
  // ??
  COMPANY: 'company',
  // ??
  COMPANY_ATTEST: 'company-attest',
  // ??
  COMPANY_ATTEST_COMPLETE: 'company-attest-complete',
  // ??
  COMPANY_CREATION_COMPLETE: 'company-creation-complete',
  // ??
  COMPANY_CREATION_UPDATE: 'company-creation-update',
  // ??
  INVEST_COMPLETE: 'invest-complete',
  // ??
  INVEST_CONTINUE: 'invest-continue',
  // ??
  INVEST_SIGN: 'invest-sign',
  // ??
  INVEST_SUMMARY: 'invest-summary',
};

export const JOURNEY_STEP = {
  BANK_COMPLETE: 'bankComplete',
  INVESTOR: 'investorComplete',
  COMPANY_STAGE1: 'companyFirstStage',
  COMPANY_STAGE2: 'companyFinalStage',
  BANK_TEMPLATE: 'bank-template',
  COMPANY_TEMPLATE: 'company-template',
  COMPANY_CREATION_UPDATE: 'company-creation-update',
};

const journeyStages = new Map();
journeyStages.set(JOURNEY.BANK_COMPLETE, 1);
journeyStages.set(JOURNEY.BANK_FORWARD_INVESTOR, 1);
journeyStages.set(JOURNEY.BANK_TRANSMIT, 1);
journeyStages.set(JOURNEY.INVEST_COMPLETE, 1);
journeyStages.set(JOURNEY.INVEST_CONTINUE, 1);
journeyStages.set(JOURNEY.COMPANY, 1);
journeyStages.set(JOURNEY.COMPANY_CREATION_UPDATE, 2);
journeyStages.set(JOURNEY.COMPANY_CREATION_COMPLETE, 2);
journeyStages.set(JOURNEY.COMPANY_ATTEST, 2);
journeyStages.set(JOURNEY.COMPANY_ATTEST_COMPLETE, 2);
journeyStages.set(JOURNEY.BANK_FORWARD_COMPANY_2, 2);

const journeySteps = new Map();
journeySteps.set(JOURNEY.INVEST_COMPLETE, JOURNEY_STEP.INVESTOR);
journeySteps.set(JOURNEY.BANK_FORWARD_INVESTOR, JOURNEY_STEP.INVESTOR);
journeySteps.set(JOURNEY.COMPANY, JOURNEY_STEP.COMPANY_STAGE1);
journeySteps.set(JOURNEY.COMPANY_CREATION_UPDATE, JOURNEY_STEP.COMPANY_STAGE2);
journeySteps.set(JOURNEY.COMPANY_ATTEST, JOURNEY_STEP.COMPANY_STAGE2);
journeySteps.set(JOURNEY.BANK_COMPLETE, JOURNEY_STEP.BANK_COMPLETE);
journeySteps.set(JOURNEY.BANK_TEMPLATE, JOURNEY_STEP.BANK_TEMPLATE);
journeySteps.set(JOURNEY.COMPANY_TEMPLATE, JOURNEY_STEP.COMPANY_TEMPLATE);
journeySteps.set(JOURNEY.BANK_FORWARD_COMPANY_2, JOURNEY_STEP.COMPANY_STAGE2);
journeySteps.set(JOURNEY.COMPANY_ATTEST_COMPLETE, JOURNEY_STEP.COMPANY_STAGE2);
journeySteps.set(JOURNEY.COMPANY_CREATION_COMPLETE, JOURNEY_STEP.COMPANY_STAGE2);

/**
 * Returns the stage (1 or 2) related to the journey name.
 * @param {*} journeyName
 * @return {number} the stage 1 OR 2 (default value is 1 if the name of the journey is not valid)
 */
export const getStageFromJourney = journeyName => {
  return journeyStages.has(journeyName) ? journeyStages.get(journeyName) : 1;
};

export const getJourneyStepFormJourney = () => {
  const currentJourney = getCurrentJourney();
  return journeySteps.has(currentJourney) ? journeySteps.get(currentJourney) : null;
};

/**
 * Returns the stage of the current journey (stored into redux)
 * @return {number} the stage 1 OR 2 (default value is 1 if the name of the journey is not valid)
 */
export const getCurrentStage = () => {
  const currentJourney = getCurrentJourney();
  return getStageFromJourney(currentJourney);
};

export const isBankComplete = () => {
  return getCurrentJourney() === JOURNEY.BANK_COMPLETE;
};

export const isBankTemplate = () => {
  return getCurrentJourney() === JOURNEY.BANK_TEMPLATE;
};

export const isInvestorComplete = () => {
  return getCurrentJourney() === JOURNEY.INVEST_COMPLETE;
};

export const isCompanyFirstStage = () => {
  return getCurrentJourney() === JOURNEY.COMPANY;
};

export const isCompanyCreateUpdateStage = () => {
  const journey = getCurrentJourney();
  return journey === JOURNEY_STEP.COMPANY_CREATION_UPDATE;
};

export const isCompanyFinalStage = () => {
  const journey = getCurrentJourney();
  return (
    journey === JOURNEY.COMPANY_CREATION_UPDATE ||
    journey === JOURNEY.COMPANY_ATTEST ||
    journey === JOURNEY.COMPANY_ATTEST_COMPLETE ||
    journey === JOURNEY.COMPANY_CREATION_COMPLETE ||
    journey === JOURNEY.BANK_FORWARD_COMPANY_2
  );
};

export const isCompanyStage = () => {
  return isCompanyFirstStage() || isCompanyFinalStage();
};

export const isCoInvestor = () => {
  return (
    isUserInvestor() &&
    getStore().getState().investment.first_investor_email !== getStore().getState().user.email
  );
};

export const isForwardModeForInvestorStage = () => {
  return getCurrentJourney() === JOURNEY.BANK_FORWARD_INVESTOR;
};

export const isForwardModeForCompanyStage2 = () => {
  return getCurrentJourney() === JOURNEY.BANK_FORWARD_COMPANY_2;
};

export const isJourneyDefined = () => isNotBlank(getCurrentJourney());

export const isUserCompany = () => {
  return utils.users.isCompanyRole(getCurrentUserType());
};

export const isUserReadOnly = () => {
  return getCurrentUserType() === Enum.User.Role.OBSERVER_ROLE;
};

export const isUserInvestor = () => {
  return utils.users.isInvestorRole(getCurrentUserType());
};

export const isUserBank = () => {
  return utils.users.isBankRole(getCurrentUserType());
};

export const isBackoffice = () => {
  return getCurrentUserType() === Enum.User.Role.BACKOFFICE_ROLE;
};

export const isUserAdvisor = () => {
  return getCurrentUserType() === Enum.User.Role.ADVISOR_ROLE;
};

export const getCurrentUserType = () => getStore().getState().user.type;

export const getCurrentJourney = () => getStore().getState().default.journey;

export const getCurrentInvestmentCompany = () => getStore().getState().investment.company;

export const isBankTemplateJourney = () => getCurrentJourney() === JOURNEY.BANK_TEMPLATE;

export const isCompanyTemplateJourney = () => getCurrentJourney() === JOURNEY.COMPANY_TEMPLATE;

export const isTemplateJourney = () =>
  getCurrentJourney() === JOURNEY.BANK_TEMPLATE || getCurrentJourney() === JOURNEY.COMPANY_TEMPLATE;
