import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './styles.scss';

const DebugCounter = ({ renderCount }) => {
  const debug = useSelector(state => state.common.debug);
  if (debug) {
    return (
      <>
        <div className="counter">Render Count: {renderCount}</div>
      </>
    );
  }
  return <></>;
};

DebugCounter.propTypes = {
  renderCount: PropTypes.number.isRequired,
};

export default DebugCounter;
