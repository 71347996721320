import React from 'react';
import PropTypes from 'prop-types';
import '../style.scss';

const CatalizrServiceMessage = () => {
  return (
    <div className="login__container__form--WRONG-URL">
      <p>
        Nous sommes ravis que vous connaissiez et souhaitiez utiliser notre service. Nous espérons
        qu’il sera à la hauteur de vos exigences !
      </p>
      <p>
        Afin de vous connecter, nous vous remercions de suivre le lien reçu dans l’un de nos emails.
        A défaut, n’hésitez pas à contacter votre Conseiller clientèle ou notre support
        (support@catalizr.eu).
      </p>
    </div>
  );
};

CatalizrServiceMessage.defaultProps = {};

CatalizrServiceMessage.propTypes = {
  defaultProps: PropTypes.object,
};
export default CatalizrServiceMessage;
