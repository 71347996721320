import * as userConst from '../const/user.const';
import * as commonConst from '../const/common.const';

import { getUserInfos, logout } from '../../api/user.api';
import { getCurrentPortfolioType } from '../../../shared/utils/CatalizrUtils';
import * as investorConst from '../../../shared/actions/const/investor.const';
import { Enum } from '@utocat/catalizr-const-sharing-lib';
// specific to user account (keep user data in the store in case of email/phone modification)
export const setUserEmail = email => ({ type: userConst.SET_USER_EMAIL, email });
// specific to login page (reset user data in the store in case of email input changed : password field is disabled)
export const setUserEmailLogin = email => ({ type: userConst.SET_USER_EMAIL_LOGIN, email });

export const getUserPending = () => ({ type: userConst.GET_USER_INFOS_PENDING });
export const getUserFulfilled = user => ({ type: userConst.GET_USER_INFOS_FULFILLED, user });

export const setUserData = user => ({ type: userConst.SET_USER_DATA, user });

export const manageUser = (user, dispatch) => {
  dispatch(setUserData({ ...user }));
};

export const userInfos = dispatch => ({
  type: 'GET_USER_INFOS',
  payload: new Promise((resolve, reject) => {
    getUserInfos().then(payload => {
      if (payload.data.data.investor) {
        dispatch({
          type: investorConst.INIT_INVESTOR,
          investor: {
            ...payload.data.data.investor,
            currentPortfolioType: getCurrentPortfolioType(),
          },
        });
      } else if (payload.data.data.role === Enum.User.Role.INVESTOR_ROLE) {
        dispatch({
          type: investorConst.INIT_INVESTOR,
          investor: payload.data.data,
        });
      }
      resolve(payload.data.data);
    }, reject);
  }),
});

export const setLocale = locale => ({ type: userConst.SET_USER_LOCALE, payload: locale });

export function userLogout() {
  return dispatch => {
    dispatch({
      type: commonConst.CLEAR_LOGOUT,
      payload: logout(),
    });
  };
}

/**
 * Updates user data into redux
 * @param {object} userData containing user properties to update into redux
 */
export const updateUserData = userData => ({ type: userConst.UPDATE_USER_DATA, userData });
