export default {
  input: {
    Root: {
      style: () => ({
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '1px',
      }),
    },
    Input: {
      style: () => ({
        fontSize: '14px',
      }),
    },
    InputContainer: {
      style: ({ $theme }) => ({ backgroundColor: $theme.colors.white }),
    },
  },
};
