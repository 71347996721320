export const INIT_FORM_PENDING = 'INVEST_DETAILS_INIT_FORM_PENDING';
export const INIT_FORM_FULFILLED = 'INVEST_DETAILS_INIT_FORM_FULFILLED';
export const INIT_FORM_REJECTED = 'INVEST_DETAILS_INIT_FORM_REJECTED';

export const SET_COMPANY_DATA = 'INVEST_DETAILS_SET_COMPANY_DATA';
export const SET_INVESTOR_DATA = 'INVEST_DETAILS_SET_INVESTOR_DATA';
export const SET_INVESTMENT_DATA = 'INVEST_DETAILS_SET_INVESTMENT_DATA';
export const SET_FINANCIAL_BROKER_DATA = 'INVEST_DETAILS_SET_FINANCIAL_BROKER_DATA'; //TODO:

export const SUBMIT_FORM_PENDING = 'INVEST_DETAILS_SUBMIT_FORM_PENDING';
export const SUBMIT_FORM_FULFILLED = 'INVEST_DETAILS_SUBMIT_FORM_FULFILLED';
export const SUBMIT_FORM_REJECTED = 'INVEST_DETAILS_SUBMIT_FORM_REJECTED';

export const SUBMIT_TEMPLATE_FORM_PENDING = 'INVEST_DETAILS_SUBMIT_TEMPLATE_FORM_PENDING';
export const SUBMIT_TEMPLATE_FORM_FULFILLED = 'INVEST_DETAILS_SUBMIT_TEMPLATE_FORM_FULFILLED';
export const SUBMIT_TEMPLATE_FORM_REJECTED = 'INVEST_DETAILS_SUBMIT_TEMPLATE_FORM_REJECTED';

export const CONTACT_SUPPORT_PENDING = 'INVEST_DETAILS_CONTACT_SUPPORT_PENDING';
export const CONTACT_SUPPORT_FULFILLED = 'INVEST_DETAILS_CONTACT_SUPPORT_FULFILLED';
export const CONTACT_SUPPORT_REJECTED = 'INVEST_DETAILS_CONTACT_SUPPORT_REJECTED';

export const SEND_EMAIL = 'INVEST_DETAILS_SEND_EMAIL';
export const SET_COMPANY_SIREN_NOT_USED = 'COMPANY_SET_SIREN_NOT_USED';

export const SET_INVEST_DETAILS_ERROR = 'SET_INVEST_DETAILS_ERROR';
export const CLEAR_INVEST_DETAILS_ERROR = 'CLEAR_INVEST_DETAILS_ERROR';

export const SHOW_IDENTITY_PROOF_MODAL = 'SHOW_IDENTITY_PROOF_MODAL';

// TODO translate errors
export const ERROR_MESSAGE_INSEE_SIREN_NOT_FOUND =
  'Catalizr n’a pas identifié de société sous cette référence. Veuillez vérifier votre saisie avant de poursuivre l’identification de la société.';
export const ERROR_MESSAGE_INSEE_NO_RESPONSE =
  'Notre service de vérification est momentanément indisponible. Votre saisie ne peut être vérifiée. Vérifiez bien vos informations avant de soumettre.';
export const ERROR_MESSAGE_INSEE_PARTIAL_RESPONSE =
  'Notre service de vérification ne retourne pas toutes les informations requises pour ce SIREN. Veuillez compléter les informations manquantes.';

export const INIT_TEMPLATE_FORM_PENDING = 'INVEST_DETAILS_INIT_TEMPLATE_FORM_PENDING';
export const INIT_TEMPLATE_FORM_FULFILLED = 'INVEST_DETAILS_INIT_TEMPLATE_FORM_FULFILLED';
export const INIT_TEMPLATE_FORM_REJECTED = 'INVEST_DETAILS_INIT_TEMPLATE_FORM_REJECTED';
export const SET_PART_TYPES_LIST = 'INVEST_DETAILS_SET_PART_TYPES_LIST';
export const SET_COMPANIES_IDENTIFICATION_INFOS =
  'INVEST_DETAILS_SET_COMPANIES_IDENTIFICATION_INFOS';

export const START_NEW_ERROR_TEMPLATE_PENDING =
  'INVEST_DETAILS_COMPANY_INIT_TEMPLATE_NEW_ERROR_PENDING';
export const START_NEW_ERROR_TEMPLATE_REJECTED =
  'INVEST_DETAILS_COMPANY_INIT_TEMPLATE_NEW_ERROR_REJECTED';
export const START_NEW_ERROR_TEMPLATE_FULFILLED =
  'INVEST_DETAILS_COMPANY_INIT_TEMPLATE_NEW_ERROR_FULFILLED';
