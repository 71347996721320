import { get, post, patch } from './apicaller';

export const getUserInfos = () => get(`${process.env.REACT_APP_BACKEND_URL}/users`, [400]);

/**
 * Set or reset user password
 * @param {string} password set by user in form
 * @param {string} token in URL when user validate password form
 * @returns journey_name and investmentLinkId if needed [optional]
 */
export const setUserAccountPassword = (password, token) =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/api/authorize/password/reset`,
    {
      password,
      token,
    },
    [400],
  );

/**
 * Get user password
 * @param {string} token in URL when user validate password form
 * @returns journey_name and investmentLinkId if needed [optional]
 */
export const getUserAccountPassword = (token, action) =>
  get(
    `${process.env.REACT_APP_BACKEND_URL}/api/authorize/password/request/${token}?action=${action}`,
    [400],
  );

export const logout = () => {
  return post(`${process.env.REACT_APP_BACKEND_URL}/logout`, []);
};

/**
 * Update the user data.
 * @param {*} body Object containing the account OR parameters data to update.
 * Format for account data :
 * {
    account: {
         "title": "Mr",
         "first_name": "XXXXXX",
         "last_name": "XXXXX",
         "address":"3 rue XXXX",
         "zip":"99999",
         "city":"Villeneuve d'Ascq",
         "country":"France",
         "iban":"FRFR"
    }
}
Format for parameter data :
{
    parameter: {
        "email": "xxxxx@xxxx.vom",
        "phone": "+33 X XX XX XX"
    }
}
 */
export const updateUser = body => patch(`${process.env.REACT_APP_BACKEND_URL}/users`, body, []);
