import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userInfos } from '../../shared/actions/actions/user.actions';

/**
 * Wrap main router to catch user and redirect this if isn't logged
 */
const User = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);

  useEffect(() => {
    /** Initialiazing & Retrieving user informations */
    checkUser();
  }, []);

  const checkUser = async () => {
    if (user.logged === true) {
      try {
        await dispatch(userInfos(dispatch));
      } catch (error) {
        console.error(error);
        history.push('/login?redirect=true');
      }
    }
  };

  const { children } = props;
  return <div>{children}</div>;
};

User.propTypes = {
  userInfos: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.node,
};

export default User;
