import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

const FieldWrapper = props => {
  const containerClass = props.row ? `row mb-4 ${props.className}` : props.className;
  const labelClass = props.row ? `col-4 ${props.className}` : props.className;
  return (
    <div className={containerClass} data-testid={props.dataTestId}>
      {!!props.label && (
        <Label
          className={labelClass}
          hasTooltip={props.hasTooltip}
          label={props.label}
          tooltipText={props.tooltipText}
          tooltipContent={props.tooltipContent}
          disabled={props.disabled}
          mountNode={props.mountNode}
          overrides={props.overrides}
        />
      )}
      {props.children}
      {props.error && props.error.message && (
        <div className={'error-input'}>{props.error.message}</div>
      )}
    </div>
  );
};

FieldWrapper.defaultProps = {
  hasTooltip: false,
  row: false,
};

FieldWrapper.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  row: PropTypes.bool,
  disabled: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  label: PropTypes.string,
  mountNode: PropTypes.node,
  overrides: PropTypes.object,
  tooltipText: PropTypes.string,
  tooltipContent: PropTypes.node,
  // react
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  // automatic value passed by Controller of React Hook Form
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
};

export default FieldWrapper;
