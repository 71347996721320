import { Enum } from '@utocat/catalizr-const-sharing-lib';

// Actions
import * as CommonAction from './invest-details-common.actions';
import {
  dispatchChangeToInvestDetailsForm,
  formatFloatValue,
} from '../../../shared/utils/CatalizrFormUtils';

// Constants
import { INVEST_DETAILS_REDUX_OBJECTS_NAMING } from '../../../shared/utils/CatalizrFormUtils';

// --------------------------------------------------------------------------------------------------------
// DATA INITIALIZATION
// --------------------------------------------------------------------------------------------------------

/**
 * Initializes the financial broker data into redux and the 'invest-details' redux form.
 * @param {object} investment the investment data containing the financial broker to initialize
 * @param {function} dispatch the redux dispatch function
 * @void (dispatch the result data to redux & redux-form)
 */
export const initInvestDetailsWithFinancialBrokerData = (investment, dispatch) => {
  let financialBrokerData = {};
  let companyUser = null;

  if (investment.is_crowdfunding) {
    companyUser = { email: investment.intermediary_email };
    financialBrokerData = investment.company?.financial_intermediary;
    financialBrokerData.crowdfunding_fees = investment.crowdfunding_fees;
    financialBrokerData.isFromCrowdFunding = Enum.PDF.FieldOptionValue.TRUE;
  } else {
    financialBrokerData.isFromCrowdFunding = Enum.PDF.FieldOptionValue.FALSE;
  }

  dispatchFinancialBrokerData(dispatch, financialBrokerData, companyUser);
};

// --------------------------------------------------------------------------------------------------------
// DATA CREATION & UPDATE
// --------------------------------------------------------------------------------------------------------

/**
 * Creates the object containing the financial broker data to send to the validate function.
 * @param {*} financialBrokerDataFromForm the financial broker data contained in the form
 */
export const buildFinancialBrokerData = financialBrokerDataFromForm => {
  // data
  const financialBrokerData = {
    siren: financialBrokerDataFromForm.siren,
    legal_form: financialBrokerDataFromForm.legal_form,
    name: financialBrokerDataFromForm.name,
    address: financialBrokerDataFromForm.address,
    zip: financialBrokerDataFromForm.zip,
    city: financialBrokerDataFromForm.city,
    country: financialBrokerDataFromForm.country,
    data_from_insee: !!financialBrokerDataFromForm.data_from_insee,
    email: financialBrokerDataFromForm.user?.email,
    crowdfunding_fees: financialBrokerDataFromForm.crowdfundingFees
      ? formatFloatValue(financialBrokerDataFromForm.crowdfundingFees)
      : 0,
  };

  // send the fb when the user has selected a financial broker in suggestion
  if (financialBrokerDataFromForm.id) {
    financialBrokerData.id = financialBrokerDataFromForm.id;
  }

  return financialBrokerData;
};

// -----------------------------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------------------------

const dispatchFinancialBrokerData = (dispatch, financialBrokerData, companyUser = null) => {
  const dataToDispatch = buildFinancialBrokerDataToDispatch(financialBrokerData, companyUser);
  // dispatch the financial broker data to the redux form
  dispatchChangeToInvestDetailsForm(
    INVEST_DETAILS_REDUX_OBJECTS_NAMING.financialBroker,
    dataToDispatch,
    dispatch,
  );
  // dispatch the financial broker data to redux
  dispatch(CommonAction.setFinancialBrokerData(dataToDispatch));
  // init the available legal forms
  dispatch(CommonAction.initLegalFormsAndBossStatuses());
};

const buildFinancialBrokerDataToDispatch = (financialBroker, companyUser) => {
  const financialBrokerData = {
    id: financialBroker.id,
    siren: financialBroker.siren,
    legal_form: financialBroker.legal_form,
    name: financialBroker.name,
    address: financialBroker.address,
    city: financialBroker.city,
    zip: financialBroker.zip,
    country: financialBroker.country,
    registered_country: financialBroker.registered_country,
    bank_name: financialBroker.bank_name,
    iban: financialBroker.iban,
    data_from_insee: financialBroker.data_from_insee,
    is_ceased: financialBroker.is_ceased,
    crowdfundingFees: financialBroker.crowdfunding_fees,
    isFromCrowdFunding: financialBroker.isFromCrowdFunding,
  };

  if (companyUser) {
    financialBrokerData.user = { ...companyUser };
  }

  return financialBrokerData;
};
