export default {
  checkbox: {
    Root: {
      style: () => ({
        display: 'flex !important',
        gap: '5px',
      }),
    },
    Checkmark: {
      style: () => ({
        minWidth: '20px !important',
      }),
    },
    Label: {
      style: ({ $theme }) => ({
        color: $theme.colors.primary,
        fontSize: '14px',
        fontWeight: 200,
      }),
    },
    Toggle: {
      style: () => ({}),
    },
    ToggleInner: {
      style: () => ({}),
    },
    ToggleTrack: {
      style: () => ({}),
    },
  },
  checkboxForTable: {
    Root: {
      style: () => ({
        display: 'flex !important',
        'justify-content': 'center',
      }),
    },
    Checkmark: {
      style: () => ({
        minWidth: '20px !important',
      }),
    },
    Label: {
      style: ({ $theme }) => ({
        color: $theme.colors.primary,
        fontSize: '14px',
        fontWeight: 200,
      }),
    },
    Toggle: {
      style: () => ({}),
    },
    ToggleInner: {
      style: () => ({}),
    },
    ToggleTrack: {
      style: () => ({}),
    },
  },
};
