import { get, post, put } from './apicaller';

export const getInvestorById = id =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investors/${id}`, []);

export const getInvestorByUuidFromShareholding = investorUuid =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investors/${investorUuid}/shareholding`, []);

export const updateInvestor = (id, body) =>
  put(`${process.env.REACT_APP_BACKEND_URL}/api/investors/${id}`, body, []);

export const createInvestor = body =>
  post(`${process.env.REACT_APP_BACKEND_URL}/api/investors`, body, []);

export const getInvestorByEmail = (email, allDomains = false) => {
  // This param is provided if we don't know the subdomain linked to the investor email we want to check
  const allDomainsParamProvided = allDomains ? '?alldomains=true' : '';
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/investors/email/${email}${allDomainsParamProvided}`,
    [404, 409],
  );
};

export const getInvestorByEmailAndUuidLink = (operationLinkUuid, email) => {
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/links/${operationLinkUuid}/investors/email/${email}`,
    [404, 409],
  );
};

export const checkSuggestedOperations = (advisorEmail, investorEmail) => {
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/draft/check?investorEmail=${investorEmail}&advisorEmail=${advisorEmail}`,
    [404],
  );
};
