import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSelector, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

// Joi schema
import { emailSchema, loginSchema } from './components/login-form.joi.schema';

// Components
import DebugCounter from '../../components/DebugCounter';
import LoginForm from './components/login-form';
import CatalizrLogo from './components/catalizr-logo';
import CatalizrBanner from './components/catalizr-banner';
import CatalizrFooter from './components/catalizr-footer';
import BankLogo from './components/bank-logo';
import CatalizrServiceMessage from './components/catalizr-service-message';
import CatalizrLoginMessage from './components/catalizr-login-messages';

// Actions
import {
  setBanners,
  clearMessages,
  initView,
  displayRedirectedAccessDenied,
  onSubmit,
} from './actions/login.actions';
import { setUserEmailLogin, userLogout } from '../../shared/actions/actions/user.actions';
import { fetchSSOStatus, fetchSSOStatusByEmail } from '../../shared/actions/actions/sso.actions';
import { setJourney } from '../../redux/actions';

// Utils
import { getSubdomain } from '../../shared/utils/helper';

import './style.scss';

let renderCount = 0;

const LoginPage = props => {
  renderCount++;
  const dispatch = useDispatch();
  const locationSearch = new URLSearchParams(location.search);
  const redirect = locationSearch.get('redirect');
  const unavailable = locationSearch.get('unavailable');
  const common = useSelector(state => state.common);
  const userEmail = useSelector(state => state?.user?.email);
  const sso = useSelector(state => state.sso);

  const [step, setStep] = useState(1);
  const [isHiddenLogButton, setIsHiddenLogButton] = useState(false);
  const [rememberEmail, setRememberEmail] = useState(
    () => localStorage.getItem('rememberEmail') === 'true',
  );

  const myDefaultValues = () => {
    return {
      email: localStorage.getItem('savedEmail') || userEmail || '',
      password: '',
    };
  };

  const form = useForm({
    shouldUnregister: true,
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUseNativeValidation: false,
    defaultValues: myDefaultValues(),
    resolver: async (values, context, options) => {
      const joiContext = {
        ...context,
        abortEarly: false,
      };
      const resolver = joiResolver(step === 1 ? emailSchema : loginSchema, joiContext, {
        mode: 'async',
      });
      const result = await resolver(values, joiContext, options);
      if (common.debug && result && result.errors && Object.keys(result.errors).length > 0) {
        // console.warn('JOI - Erreurs de validation trouvées !');
        // console.warn('JOI - values!', values);
        // console.warn('JOI - result with errors', result);
      }
      return result;
    },
  });

  // Listen for hide login button depends on state login message
  const hiddenLoginButton = useCallback(
    isHidden => {
      return setIsHiddenLogButton(isHidden);
    },
    [setIsHiddenLogButton],
  );

  // Listen to store user email on local storage
  const memoizedemail = useMemo(() => form.watch('email'), [form.watch('email')]);
  useEffect(() => {
    if (rememberEmail && memoizedemail) {
      localStorage.setItem('savedEmail', memoizedemail);
    }
  }, [memoizedemail, rememberEmail]);

  // Listen for local storage email valid format
  useEffect(() => {
    if (rememberEmail) {
      const savedEmail = localStorage.getItem('savedEmail');
      if (savedEmail) {
        const { error } = emailSchema.validate({ email: savedEmail });
        if (error) {
          form.setError('email', {
            type: 'manual',
            message: error.details[0].message,
          });
        }
      }
    }
  }, []);

  // Listen for init basic data on login
  useEffect(() => {
    localStorage.removeItem('state');
    const init = async () => {
      if (getSubdomain()) {
        await dispatch(userLogout());
        await dispatch(initView(props.location, props.history));
        await dispatch(setBanners());
      }
      if (props.initJourney) {
        dispatch(setJourney(''));
      }
      if (redirect || unavailable) {
        dispatch(displayRedirectedAccessDenied());
      }
    };
    init().catch(error => {
      console.error(error);
    });
  }, []);

  // Listen for check SSO status on includes 'SSORedirect' path
  useEffect(() => {
    if (locationSearch.get('SSORedirect') === 'true') {
      dispatch(fetchSSOStatus());
    }
  }, []);

  // Listen for SSO redirection
  useEffect(() => {
    if (sso?.enabled && sso?.redirectUrl) {
      if (!location?.pathname?.includes('/login')) {
        localStorage?.setItem('ssoRedirectUrl', location?.pathname);
      }
      window.location.assign(sso.redirectUrl);
      return;
    }
  }, [sso?.enabled]);

  // Listen for 'remenber-login-email' checkbox
  const handleRememberEmailChange = ({ target: { checked } }) => {
    setRememberEmail(checked);
    if (checked) {
      localStorage.setItem('rememberEmail', 'true');
      localStorage.setItem('savedEmail', form.getValues('email'));
    } else {
      localStorage.removeItem('rememberEmail');
      localStorage.removeItem('savedEmail');
    }
  };

  // Listen email and password change input
  const handleChange = (name, e, onChange, currentStep) => {
    const value = e.target.value || '';
    onChange(value);
    switch (name) {
      case 'email':
        if (currentStep === 2 && userEmail?.length) {
          dispatch(setUserEmailLogin(''));
          setStep(1);
          dispatch(clearMessages());
        } else {
          dispatch(setUserEmailLogin(value.toLowerCase()));
          dispatch(clearMessages());
        }
        break;

      default:
        console.warn(`Champ non géré : ${name}`);
        break;
    }
  };

  // On click 'Continuer'
  const handleContinue = form.handleSubmit(async data => {
    try {
      if (!userEmail?.length && data?.email) {
        dispatch(setUserEmailLogin(data?.email.toLowerCase()));
        dispatch(clearMessages());
      }
      await dispatch(fetchSSOStatusByEmail(data?.email));
      setStep(2);
    } catch (error) {
      form.setError('email', {
        type: 'manual',
        message: 'Email non reconnu',
      });
    }
  });

  // On click 'Se connecter'
  const handleLogin = form.handleSubmit(async data => {
    try {
      dispatch(onSubmit(data.email, data.password, props.history, props.location));
    } catch (error) {
      form.setError('root', {
        type: 'manual',
        message: 'Identifiants invalides',
      });
    } finally {
      // to do
    }
  });

  const LoginFormProvider = (
    <FormProvider {...form}>
      <form
        className={`login__container__form${!getSubdomain() ? '__wrong-url' : ''}`}
        id="hook-form"
        data-testid="login__form"
        onSubmit={step === 1 ? handleContinue : handleLogin}
      >
        <BankLogo />
        {!getSubdomain() ? (
          <CatalizrServiceMessage />
        ) : (
          <LoginForm
            step={step}
            handleChange={handleChange}
            userEmail={userEmail}
            rememberEmail={rememberEmail}
            handleRememberEmailChange={handleRememberEmailChange}
            isHiddenLogButton={isHiddenLogButton}
          />
        )}
        {common.debug && <DebugCounter renderCount={renderCount} />}
        <CatalizrLoginMessage hiddenLoginButton={hiddenLoginButton} />
      </form>
    </FormProvider>
  );

  return (
    <div className="login__container">
      <CatalizrBanner />
      <CatalizrLogo />
      {LoginFormProvider}
      <CatalizrFooter formState={form.formState} />
    </div>
  );
};

LoginPage.defaultProps = {};

LoginPage.propTypes = {
  initJourney: PropTypes.bool.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default LoginPage;
