import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Input as BaseUiInput } from 'baseui/input';
import FieldWrapper from '../FieldWrapper';

// Styles
import styles from './styles';

const Input = props => {
  if (props.type === 'number') {
    throw new Error('use NumberInput component');
  }

  if (props.type === 'date') {
    throw new Error('use DatePicker component');
  }

  return (
    <FieldWrapper
      className={'input'}
      data-testid={props.dataTestId}
      disabled={props.disabled}
      error={props.error}
      hasTooltip={props.hasTooltip}
      label={props.label}
      tooltipText={props.tooltipText}
      tooltipContent={props.tooltipContent}
      mountNode={props.mountNode}
      overrides={props.tooltipOverrides}
    >
      <BaseUiInput
        id={props.id}
        disabled={props.disabled}
        clearable={props.clearable}
        endEnhancer={props.endEnhancer}
        error={props.invalid}
        maxLength={props.maxLength}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        overrides={styles.input}
        pattern={props.pattern}
        placeholder={props.placeholder}
        startEnhancer={props.startEnhancer}
        type={props.type}
        value={props.value}
      />
    </FieldWrapper>
  );
};

Input.defaultProps = {
  disabled: false,
  hasTooltip: false,
  maxLength: 255,
  clearable: false,
};

Input.propTypes = {
  clearable: PropTypes.bool,
  id: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  endEnhancer: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  invalid: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  // others
  hasTooltip: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  mountNode: PropTypes.node,
  onKeyDown: PropTypes.func,
  tooltipOverrides: PropTypes.object,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  startEnhancer: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipContent: PropTypes.node,
  type: PropTypes.string.isRequired,
};

export default Input;
