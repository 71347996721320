export const SET_POTENTIAL_PLACES = 'COMMON_SET_POTENTIAL_PLACES';
export const SET_IS_LOADING = 'COMMON_SET_IS_LOADING';
export const GET_POTENTIAL_PLACES = 'COMMON_GET_POTENTIAL_PLACES';
export const GET_POTENTIAL_PLACES_PENDING = 'COMMON_GET_POTENTIAL_PLACES_PENDING';
export const GET_POTENTIAL_PLACES_FULFILLED = 'COMMON_GET_POTENTIAL_PLACES_FULFILLED';
export const GET_POTENTIAL_PLACES_REJECTED = 'COMMON_GET_POTENTIAL_PLACES_REJECTED';

export const CLEAR_REDUX = 'COMMON_CLEAR_REDUX';
export const CLEAR_LOGOUT = 'COMMON_CLEAR_LOGOUT';
export const CLEAR_LOGOUT_FULFILLED = 'COMMON_CLEAR_LOGOUT_FULFILLED';
export const CLEAR_LOGOUT_PENDING = 'COMMON_CLEAR_LOGOUT_PENDING';

export const SET_INFORMATION_MESSAGE = 'COMMON_SET_INFORMATION_MESSAGE';
export const SET_ERROR_MESSAGE = 'COMMON_SET_ERROR_MESSAGE';
export const CLEAR_MESSAGES = 'COMMON_CLEAR_MESSAGES';
export const SET_GO_NEXT = 'COMMON_SET_GO_NEXT';
export const INIT_STATE = 'COMMON_INIT_STATE';

export const GET_CURRENCIES = 'COMMON_GET_CURRENCIES';
export const GET_BANNERS = 'COMMON_GET_BANNERS';
export const GET_INSTRUMENT_TYPES = 'COMMON_GET_INSTRUMENT_TYPES';
export const GET_INSTRUMENT_TYPES_PENDING = 'COMMON_GET_INSTRUMENT_TYPES_PENDING';
export const GET_INSTRUMENT_TYPES_FULFILLED = 'COMMON_GET_INSTRUMENT_TYPES_FULFILLED';
export const GET_INSTRUMENT_TYPES_REJECTED = 'COMMON_GET_INSTRUMENT_TYPES_REJECTED';

export const SET_DRAFT_MODE = 'SET_DRAFT_MODE';
export const SET_DRAFT_MODE_EDITION = 'SET_DRAFT_MODE_EDITION';
export const CLEAR_DRAFT_MODE = 'CLEAR_DRAFT_MODE';

export const REFRESH_TEMPLATE_DOCUMENT_LIST = 'REFRESH_TEMPLATE_DOCUMENT_LIST';
export const REFRESH_CUSTOM_DOCUMENT_LIST = 'REFRESH_CUSTOM_DOCUMENT_LIST';
export const EDITED_TEMPLATE_DOCUMENT = 'EDITED_TEMPLATE_DOCUMENT';
export const REFRESH_CUSTOM_FIELD_LIST = 'REFRESH_CUSTOM_FIELD_LIST';
