/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import { post } from '../api/apicaller';
import { getCurrentStage, isCoInvestor } from './JourneyUtils';
import 'url-polyfill';
import { v4 as uuidv4 } from 'uuid';
import tlds from 'tlds';
import { DocumentTypes } from './documentTypes';
import CurrencyFormatter from 'currency-formatter';
import { manageNoValue } from './CatalizrUtils';

/**
 * Update the current step in process of a user stored in the API
 *
 * @param {string} investmentLinkId The ID of investement link for this process
 * @param {string} step The new step stored in the API
 * @param {string} entityType The type of user at this step
 * @param stage
 * @param companyId
 * @param certifiedTrueConformDocument
 */
const updateJourneyStep = ({
  investmentLinkId,
  step,
  entityType,
  stage = getCurrentStage(),
  companyId,
  certifiedTrueConformDocument,
}) => {
  if (!investmentLinkId) {
    return;
  }
  post(
    `${process.env.REACT_APP_BACKEND_URL}/api/steps`,
    {
      investment_link_id: investmentLinkId,
      step,
      entity_type: entityType === 'INVESTOR' && isCoInvestor() ? 'CO_INVESTOR' : entityType,
      stage,
      companyId,
      certifiedTrueConformDocument,
    },
    [],
  ).catch(err => {
    console.error('Error while updating journey step:', err);
    return Promise.resolve();
  });
};

const createAccount = (email, code, domain) =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/users`,
    {
      email,
      code,
      domain,
    },
    [409],
  ).catch(err => {
    if (err && err.response.status === 409) {
      return Promise.resolve();
    }
    return Promise.reject(err);
  });

/**
 * Check that an email is valid
 * @param {string} email
 */
const isEmailAddressValid = email => {
  if (typeof email !== 'string' || !email) {
    return false;
  }
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )
    ? tlds.includes(email.split('.').pop())
    : false;
};

const stringToDate = value => {
  if (typeof value === 'string' || typeof value === 'object') {
    return dayjs(value).isValid() ? dayjs(value) : null;
  }
  return value;
};

const toTitleCase = str => {
  let formated;
  // " "
  str = str.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  formated = str.join(' ');
  // "-"
  let formated2;
  formated = formated.split('-');
  for (let i = 0; i < formated.length; i++) {
    formated[i] = formated[i].charAt(0).toUpperCase() + formated[i].slice(1);
  }
  formated2 = formated.join('-');
  // "'"
  let formated3;
  formated2 = formated2.split("'");
  for (let i = 0; i < formated2.length; i++) {
    formated2[i] = formated2[i].charAt(0).toUpperCase() + formated2[i].slice(1);
  }
  formated3 = formated2.join("'");

  return formated3;
};

const ucFirst = str => str.charAt(0).toUpperCase() + str.slice(1);

const getSubdomain = () => {
  const regexSubdomain = new RegExp(process.env.REACT_APP_REGEX_DOMAIN, 'i');
  // eslint-disable-next-line no-restricted-globals
  const domain = location.hostname.match(regexSubdomain);
  if (domain) {
    return domain[1];
  } else {
    return 'localhost';
  }
};

const getNewBankUrl = (newBankSubDomain, path) => {
  const oldDomain = getSubdomain();
  const url = new URL(window.location);
  url.hostname = url.hostname.replace(oldDomain, newBankSubDomain);
  url.pathname = path;
  return url;
};

const buildUrlQuery = (url, params) => {
  const builtUrl = new URL(url);
  Object.keys(params).forEach(key => builtUrl.searchParams.append(key, params[key]));
  return builtUrl.href;
};

/**
 * Generate an UUID (alias for UUID (v4) module)
 * @returns uuid as string
 */
const getUuid = () => uuidv4();

/**
 * Generate an UUID prefixed with 'BLANK' term, used for mockDocusign
 */
const getBlankUuid = () => `BLANK${getUuid()}`;

/**
 * Returns precision of a floating number
 * Explanation : fix the precision to 12, get the floating number and remove ending zeros, then calculate and return length
 * @param {number} number
 */
const getPrecision = number => number.toFixed(12).split('.')[1].replace(/0+$/g, '').length;

/**
 * Format a float number to fixed, removing potentially exponential representation, by calculating automatically the precision.
 * @param {number} number
 */
const toFixedWithoutExponential = number =>
  getPrecision(number) > 5 // JS display number as exponential if there are more than 5 zeros just after the comma
    ? number.toFixed(getPrecision(number)) // Return the number as fixed value, if original precision is > 5
    : number;

/**
 * Return generated attestation from documents list
 * @param {object[]} documents
 * @returns {object} Generated attestation document object
 */
const findGeneratedAttestation = documents =>
  documents.find(
    doc =>
      (doc.type_document === DocumentTypes.RETURN_ATTESTATION ||
        doc.type_document === DocumentTypes.RETURN_ATTESTATION_APPORT_TITRES) &&
      doc.id &&
      doc.origin === 'generate',
  );

/**
 * Return uploaded attestation from documents list
 * @param {object[]} documents
 * @returns {object} Uploaded attestation document object
 */
const findUploadedAttestation = documents =>
  documents.find(
    doc =>
      (doc.type_document === DocumentTypes.RETURN_ATTESTATION ||
        doc.type_document === DocumentTypes.RETURN_ATTESTATION_APPORT_TITRES) &&
      doc.origin === 'uploaded' &&
      doc.uploader_email,
  );

/**
 * Count status occurences on operation_status_history
 * @param {array[]} arr of status
 * @param {string} val to check
 * @returns {number} number of status find on history
 */
const countStatusOnHistory = (arr, val) =>
  arr.reduce((counter, { status }) => (status === val ? (counter += 1) : counter), 0);

const formatAmount = (value, currency, useCodeAsSymbol = false) => {
  if (value && currency) {
    return `${CurrencyFormatter.format(value, {
      code: currency,
      symbol: useCodeAsSymbol ? currency : undefined,
    })}`;
  }
  return manageNoValue(value);
};

export {
  updateJourneyStep,
  createAccount,
  isEmailAddressValid,
  stringToDate,
  toTitleCase,
  ucFirst,
  getSubdomain,
  getNewBankUrl,
  getUuid,
  getBlankUuid,
  toFixedWithoutExponential,
  findGeneratedAttestation,
  findUploadedAttestation,
  buildUrlQuery,
  getPrecision,
  countStatusOnHistory,
  formatAmount,
};
