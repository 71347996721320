import * as loginConst from '../actions/login.const';
import * as commonConst from '../../../shared/actions/const/common.const';
import { formatDuration } from '../../../shared/utils/time';

const defaultState = {
  loadingInProgress: false,
  errorMessage: '',
  infoMessage: '',
  generalErrorMessage: '',
  // will trigger a navigation to the new URL when updated
  newUrl: '',
  isFeatureEnable: false,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
    case loginConst.INIT_VIEW: {
      return defaultState;
    }
    case loginConst.CHECK_FEATURE_IS_PENDING: {
      return { ...state, loadingInProgress: true };
    }
    case loginConst.CHECK_FEATURE_IS_FULFILLED: {
      return {
        ...state,
        loadingInProgress: false,
        errorMessage: '',
        infoMessage: '',
        isFeatureEnable: action.payload.isFeatureEnable,
      };
    }
    case loginConst.CHECK_FEATURE_IS_REJECTED: {
      return {
        ...state,
        loadingInProgress: false,
        errorMessage: "Le service n'est pas disponible",
        infoMessage: '',
      };
    }
    case loginConst.REQUIRE_JWT_PENDING: {
      return { ...state, loadingInProgress: true };
    }
    case loginConst.REQUIRE_JWT_FULFILLED: {
      return { ...state, errorMessage: '', infoMessage: '', generalErrorMessage: '' };
    }
    case loginConst.REQUIRE_JWT_REJECTED: {
      let errorMessage;

      if (action.payload && !action.payload.data && !action.payload.response.data) {
        errorMessage = "Le service n'est pas disponible";
      } else if (action.payload.response.data.statusCode === 401) {
        if (
          action.payload.response.data.message ===
          'Could not authenticate. Please check your credentials'
        ) {
          errorMessage = 'Email ou mot de passe incorrect';
        } else if (
          action.payload.response.data.message ===
          'This account is inactive. Please set a password first'
        ) {
          errorMessage =
            "Vous devez activer votre compte en cliquant sur le lien de l'email de confirmation. Si vous ne l'avez pas reçu veuillez";
        } else if (
          action.payload.response.data.message ===
          'The user is not authorized to be connected to the subdomain'
        ) {
          errorMessage = "Vous n'êtes pas autorisé à vous connecter à cette banque";
        } else if (action.payload.response.data.message === 'force_reset_password') {
          errorMessage = action.payload.response.data.message;
        } else if (
          action.payload.response.data.message === 'You are banned, please try again later'
        ) {
          errorMessage = `Votre compte est bloqué jusque ${formatDuration(
            action.payload.response.data.time,
          )}, veuillez réessayer ultérieurement`;
        }
      } else {
        errorMessage = 'Veuillez vérifier votre email';
      }

      return {
        ...state,
        loadingInProgress: false,
        errorMessage,
        infoMessage: '',
      };
    }
    case loginConst.SET_NEW_URL:
      return { ...state, newUrl: action.url };
    case loginConst.RESET_PASSWORD_PENDING: {
      return { ...state, loadingInProgress: true };
    }
    case loginConst.RESET_PASSWORD_FULFILLED: {
      return {
        ...state,
        loadingInProgress: false,
        errorMessage: '',
        infoMessage:
          'Si un compte existe un email vous sera envoyé à cette adresse afin que vous puissiez réinitialiser votre mot de passe',
      };
    }
    case loginConst.RESET_PASSWORD_REJECTED: {
      let errorMessage;
      let infoMessage =
        'Si un compte existe un email vous sera envoyé à cette adresse afin que vous puissiez réinitialiser votre mot de passe';

      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.statusCode
      ) {
        if (action.payload.response.data.statusCode !== 404) {
          errorMessage = "Le service n'est pas disponible";
        }
      }
      return {
        ...state,
        loadingInProgress: false,
        infoMessage,
        errorMessage,
      };
    }

    case loginConst.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.message,
        infoMessage: '',
      };

    case loginConst.CLEAR_MESSAGES:
      return {
        ...state,
        errorMessage: '',
        infoMessage: '',
        generalErrorMessage: '',
      };

    case loginConst.REDIRECT_ACCESS_DENIED:
      return {
        ...state,
        generalErrorMessage: 'Accès non autorisé. Veuillez vous identifier.',
      };
    case loginConst.REDIRECT_UNAVAILABLE:
      return {
        ...state,
        generalErrorMessage: 'Le service est momentanément indisponible.',
      };
    default:
      return state;
  }
};

export default reducer;
