import * as registrationConst from '../actions/registration.const';
import * as commonConst from '../../../shared/actions/const/common.const';

const defaultState = {
  loadingInProgress: false,
  errorMessage: '',
  infoMessage: '',
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
    case registrationConst.INIT_VIEW: {
      return defaultState;
    }
    case registrationConst.CREATE_ADVISOR_PENDING: {
      return {
        ...state,
        loadingInProgress: true,
        errorMessage: '',
        infoMessage: '',
      };
    }
    case registrationConst.CREATE_ADVISOR_FULFILLED: {
      return {
        ...state,
        loadingInProgress: false,
        errorMessage: '',
        infoMessage: 'Un email vient de vous être envoyé',
      };
    }
    case commonConst.CLEAR_MESSAGES: {
      return {
        ...state,
        errorMessage: '',
        infoMessage: '',
      };
    }
    case registrationConst.CREATE_ADVISOR_REJECTED: {
      let errorMessage = "Votre demande n'a pas abouti";
      if (action.payload.response.data.message === 'Not allowed with this email address') {
        errorMessage =
          'Vous ne pouvez pas utiliser ce type d’adresse. Veuillez utiliser une adresse mail autorisée ou vous rapprocher de vos services internes pour plus d’informations';
      }
      return {
        ...state,
        loadingInProgress: false,
        errorMessage: errorMessage,
        infoMessage: '',
      };
    }
    case registrationConst.SET_MESSAGE: {
      return {
        ...state,
        errorMessage: action.msg,
      };
    }
    default:
      return state;
  }
};

export default reducer;
