import React from 'react';
import PropTypes from 'prop-types';
import { ACCESSIBILITY_TYPE, StatefulTooltip } from 'baseui/tooltip';
import iconQuestionmark from '../../../assets/images/picto/icon-questionmark.svg';
import './index.scss';

const Label = props => {
  let labelClass = props.disabled ? `label--disabled` : `label`;
  labelClass = props.className ? `${labelClass} ${props.className}` : labelClass;
  return (
    <span className={labelClass}>
      {props.label}
      {(props.hasTooltip || props.tooltipContent) && (
        <StatefulTooltip
          accessibilityType={ACCESSIBILITY_TYPE.tooltip}
          autoFocus
          content={() => props.tooltipContent || `${props.tooltipText}`}
          placement={'top'}
          returnFocus
          showArrow
          overrides={props.overrides}
          mountNode={props.mountNode}
        >
          <img width="28" height="18" src={iconQuestionmark} alt="?" />
        </StatefulTooltip>
      )}
    </span>
  );
};

Label.defaultProps = {
  hasTooltip: false,
  tooltipText: '',
};

Label.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  label: PropTypes.string,
  mountNode: PropTypes.node,
  overrides: PropTypes.object,
  tooltipContent: PropTypes.node,
  tooltipText: PropTypes.string,
};

export default Label;
