import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import Banner from '../../../components/Banner';

const CatalizrBanner = () => {
  const common = useSelector(state => state.common);
  const state = useMemo(
    () => ({
      common,
    }),
    [common],
  );
  const memoizedBanner = useMemo(() => {
    let banner = '';
    if (state.common?.banners?.length > 0) {
      banner = state.common.banners.map((banner, index) => {
        return (
          <Banner
            key={`banners${index}`}
            bannerCriticality={banner.criticality}
            bannerMessage={banner.message}
            bannerTitle={banner.title}
          ></Banner>
        );
      });
    }
    return banner;
  }, [state.common.banners]);

  return <>{memoizedBanner}</>;
};

CatalizrBanner.defaultProps = {};

CatalizrBanner.propTypes = {
  defaultProps: PropTypes.object,
};

export default CatalizrBanner;
