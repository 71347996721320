import Joi from 'joi';
import { utils } from '@utocat/catalizr-const-sharing-lib';

const joiEmail = utils.joi.joiEmail.messages({
  'string.email': "Format d'email invalide",
  'any.required': 'Email requis',
  'string.empty': 'Email requis',
});

export const emailSchema = Joi.object({
  email: joiEmail.required(),
});

export const loginSchema = Joi.object({
  email: joiEmail.required(),
  password: Joi.string().required().messages({
    'any.required': 'Mot de passe requis',
    'string.empty': 'Mot de passe requis',
  }),
});
