export const CHECK_FEATURE_IS_PENDING = 'LOGIN_CHECK_FEATURE_IS_PENDING';
export const CHECK_FEATURE_IS_FULFILLED = 'LOGIN_CHECK_FEATURE_IS_FULFILLED';
export const CHECK_FEATURE_IS_REJECTED = 'LOGIN_CHECK_FEATURE_IS_REJECTED';

export const REQUIRE_JWT = 'LOGIN_REQUIRE_JWT';
export const REQUIRE_JWT_PENDING = 'LOGIN_REQUIRE_JWT_PENDING';
export const REQUIRE_JWT_FULFILLED = 'LOGIN_REQUIRE_JWT_FULFILLED';
export const REQUIRE_JWT_REJECTED = 'LOGIN_REQUIRE_JWT_REJECTED';

export const SUBMIT_FORM_PENDING = 'LOGIN_SUBMIT_FORM_PENDING';
export const SUBMIT_FORM_FULFILLED = 'LOGIN_SUBMIT_FORM_FULFILLED';
export const SUBMIT_FORM_REJECTED = 'LOGIN_SUBMIT_FORM_REJECTED';

export const RESET_PASSWORD = 'LOGIN_RESET_PASSWORD';
export const RESET_PASSWORD_PENDING = 'LOGIN_RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_FULFILLED = 'LOGIN_RESET_PASSWORD_FULFILLED';
export const RESET_PASSWORD_REJECTED = 'LOGIN_RESET_PASSWORD_REJECTED';
export const INIT_VIEW = 'LOGIN_INIT_VIEW';
export const SET_NEW_URL = 'LOGIN_SET_NEW_URL';

export const SET_ERROR_MESSAGE = 'LOGIN_SET_ERROR_MESSAGE';
export const CLEAR_MESSAGES = 'LOGIN_CLEAR_INFO_MESSAGES';
export const REDIRECT_ACCESS_DENIED = 'LOGIN_REDIRECT_ACCESS_DENIED';
export const REDIRECT_UNAVAILABLE = 'LOGIN_REDIRECT_UNAVAILABLE';
