import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Registration from '../components/Registration';
import { initView, createAdvisorAccount, handleForm } from '../actions/registration.actions';
import { setUserEmail, userLogout } from '../../../shared/actions/actions/user.actions';

const Container = props => {
  const state = useSelector(state => ({
    registration: state.registration,
    user: state.user,
    bank: state.bank,
  }));
  const dispatch = useDispatch();
  return (
    <Registration
      {...props}
      {...state}
      initView={(location, history) => dispatch(initView(location, history))}
      setUserEmail={email => dispatch(setUserEmail(email))}
      createAdvisor={options => dispatch(createAdvisorAccount(options))}
      logout={() => dispatch(userLogout())}
      handleForm={(email, history, options) => dispatch(handleForm(email, history, options))}
    />
  );
};

export default withRouter(Container);
