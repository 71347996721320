import { Enum } from '@utocat/catalizr-const-sharing-lib';

export const DocumentTypes = {
  PROOF_OF_AUTHORITY: 'PROOF_OF_AUTHORITY',
  RETURN_ATTESTATION: 'RETURN_ATTESTATION',
  RETURN_ATTESTATION_APPORT_TITRES: 'RETURN_ATTESTATION_APPORT_TITRES',
  ORDRE_MOUVEMENT: 'ORDRE_MOUVEMENT',
  ACCEPTED_SUBMISSION: 'ACCEPTED_SUBMISSION',
  ATTESTATION: 'ATTESTATION',
  BULLETIN_SOUSCRIPTION: 'BULLETIN_SOUSCRIPTION',
  CERFA_FORM_2759: 'CERFA_FORM_2759',
  COST_ACCEPTATION: 'COST_ACCEPTATION',
  DEBT_SUBMISSION: 'DEBT_SUBMISSION',
  DISPOSAL_PROTOCOL: 'DISPOSAL_PROTOCOL',
  IBAN_ACHETEUR: 'IBAN_ACHETEUR',
  IBAN_VENDEUR: 'IBAN_VENDEUR',
  KBIS: 'KBIS',
  LETTRE_ENGAGEMENT: 'LETTRE_ENGAGEMENT',
  LETTRE_ENGAGEMENT_APPORT_TITRES: 'LETTRE_ENGAGEMENT_APPORT_TITRES',
  LETTRE_INTENTION: 'LETTRE_INTENTION',
  PROJECT_STATUTS: 'PROJECT_STATUTS',
  PV_DEBT_EMISSION: 'PV_DEBT_EMISSION',
  PV_END_UPGRADE_CAPITAL: 'PV_END_UPGRADE_CAPITAL',
  PV_UPGRADE_CAPITAL: 'PV_UPGRADE_CAPITAL',
  REGISTRATION_SLIP: 'REGISTRATION_SLIP',
  RIB_COMPANY: 'RIB_COMPANY',
  ATTESTATION_ADMINISTERED_REGISTERED_SHARES: 'ATTESTATION_ADMINISTERED_REGISTERED_SHARES',
  STATUTS: 'STATUTS',
  OTHER: 'OTHER',
  KYC: 'KYC',
  MEMORANDUM: 'MEMORANDUM',
  REGLEMENT_INTERIEUR: 'REGLEMENT_INTERIEUR',
  IDENTITY_DOCUMENT: 'IDENTITY_DOCUMENT',
  PROOF_OF_PAYMENT: 'PROOF_OF_PAYMENT',
  CUSTOM_TEMPLATE_DOCUMENT: 'CUSTOM_TEMPLATE_DOCUMENT',
};

export const LibraryDocumentTypes = [
  Enum.Documents.Type.KBIS,
  Enum.Documents.Type.STATUTS,
  Enum.Documents.Type.RIB_COMPANY,
  Enum.Documents.Type.PV_DEBT_EMISSION,
  Enum.Documents.Type.PV_UPGRADE_CAPITAL,
  Enum.Documents.Type.PV_END_UPGRADE_CAPITAL,
  Enum.Documents.Type.PV_DEBT_EMISSION,
  Enum.Documents.Type.DEBT_SUBMISSION,
  Enum.Documents.Type.PROJECT_STATUTS,
];
