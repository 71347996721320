import { getStore } from '../../store';
import { isFeatureEnabled } from '../../shared/utils/CatalizrUtils';
import { isTemplateJourney } from './JourneyUtils';

import {
  OPERATION_TYPE_BOND,
  OPERATION_TYPE_CAPITAL_INCREASE,
  OPERATION_TYPE_CREATE,
  OPERATION_TYPE_DISPOSAL,
  OPERATION_TYPE_KIND_CONTRIBUTION,
  OPERATION_TYPE_PRIVATE_EQUITY,
  OPERATION_TYPE_REPURCHASE,
} from './CatalizrConstants';

export const create = () => checkOperationType(OPERATION_TYPE_CREATE);
export const disposal = () => checkOperationType(OPERATION_TYPE_DISPOSAL);
export const increase = () => {
  return checkOperationType(OPERATION_TYPE_CAPITAL_INCREASE);
};
export const repurchase = () => checkOperationType(OPERATION_TYPE_REPURCHASE);
export const checkDisplayRealOrEstimateDate = () =>
  !checkOperationType(OPERATION_TYPE_DISPOSAL) && isFeatureEnabled('display.investment_date');
export const bond = () => checkOperationType(OPERATION_TYPE_BOND);
export const privateEquity = () => checkOperationType(OPERATION_TYPE_PRIVATE_EQUITY);
export const kindContribution = () => checkOperationType(OPERATION_TYPE_KIND_CONTRIBUTION);
export const templateCreate = () => checkTemplateOperationType(OPERATION_TYPE_CREATE);
export const templateDisposal = () => checkTemplateOperationType(OPERATION_TYPE_DISPOSAL);
export const templateIncrease = () => checkTemplateOperationType(OPERATION_TYPE_CAPITAL_INCREASE);
export const templateRepurchase = () => checkTemplateOperationType(OPERATION_TYPE_REPURCHASE);
export const templateKindContribution = () =>
  checkTemplateOperationType(OPERATION_TYPE_KIND_CONTRIBUTION);
export const templateBond = () => checkTemplateOperationType(OPERATION_TYPE_BOND);
export const templatePrivateEquity = () =>
  checkTemplateOperationType(OPERATION_TYPE_PRIVATE_EQUITY);
export const isPortfolioMultiple = () => checkIsPortfolioMultiple();

export const getOperationType = () => {
  const template = getStore().getState().common.template;
  const investment = getStore().getState().investment;

  return isTemplateJourney()
    ? template.operation_type.identifier
    : investment.currentInvestmentType;
};

export const getInvestmentDisposalMode = () => {
  return getStore().getState().investment.investmentDisposalMode;
};

// ----------------------------------------------------------------------------------------------
// PRIVATE FUNCTIONS
// ----------------------------------------------------------------------------------------------

/**
 * Checks if the given operation type is the current operation being processed.
 * @param {string} operationType the type of operation to check (ex: Gré à gré, Augmentation de capital, etc.)
 * @returns {boolean}
 */
export const checkOperationType = operationType => {
  return getStore().getState().investment.currentInvestmentType === operationType;
};

const checkTemplateOperationType = operationType => {
  const template = getStore().getState().common.template;
  return (
    template && template.operation_type && template.operation_type.identifier === operationType
  );
};

const checkIsPortfolioMultiple = () => {
  return !!getStore().getState().investment.portfolio_type_multiple;
};
