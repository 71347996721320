import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import SpinnerWrapper from '../../../components/Spinner';
import Footer from '../../../components/Footer/Footer';

const CatalizrFooter = ({ formState }) => {
  const memoizedFooter = useMemo(() => {
    return (
      <SpinnerWrapper isLoading={false}>
        <Footer />
      </SpinnerWrapper>
    );
  }, [formState]);

  return <>{memoizedFooter}</>;
};

CatalizrFooter.defaultProps = {};

CatalizrFooter.propTypes = {
  formState: PropTypes.instanceOf(Object).isRequired,
};

export default CatalizrFooter;
