import React, { lazy } from 'react';
import * as PropTypes from 'prop-types';
import LazyWrapper from '../../../components/LazyWrapper/index';
const CatalizrSquareLogo = lazy(() => import('../../../iconsPictos/iconCatalizrSquare'));

import '../style.scss';

const CatalizrLogo = () => (
  <LazyWrapper>
    <div className="login__container__catalizr-logo">
      <CatalizrSquareLogo width="100" height="100" alt="Catalizr logo" title="Catalizr logo" />
      <h1>Catalizr</h1>
    </div>
  </LazyWrapper>
);

CatalizrLogo.defaultProps = {};

CatalizrLogo.propTypes = {
  defaultProps: PropTypes.object,
};

export default CatalizrLogo;
