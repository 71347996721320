import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import '../style.scss';

const BankLogo = () => {
  const bank = useSelector(state => state.bank);
  const state = useMemo(
    () => ({
      bank,
    }),
    [bank],
  );
  const memoizedBankLogo = useMemo(() => {
    if (state.bank.logo) {
      return (
        <img
          className="login__container__bank-logo"
          src={`${process.env.REACT_APP_BACKEND_URL}/api${state.bank.logo}`}
          alt="bank logo"
        />
      );
    }
  }, [state.bank.logo]);
  return <>{memoizedBankLogo}</>;
};

BankLogo.defaultProps = {};

BankLogo.propTypes = {
  defaultProps: PropTypes.object,
};

export default BankLogo;
