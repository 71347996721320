/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { resetPassword } from '../actions/login.actions';

const CatalizrLoginMessage = ({ hiddenLoginButton }) => {
  const dispatch = useDispatch();
  const login = useSelector(state => state.login);
  const user = useSelector(state => state.user);
  const state = useMemo(
    () => ({
      login,
      user,
    }),
    [login],
  );

  const isExpiredPwd =
    state?.login?.expiredPwd || state?.login?.errorMessage === 'force_reset_password';

  const isNotActivatedAccount =
    state?.login?.errorMessage ===
    "Vous devez activer votre compte en cliquant sur le lien de l'email de confirmation. Si vous ne l'avez pas reçu veuillez";

  const isBanAccount = state?.login?.errorMessage?.includes('Votre compte est bloqué');

  useEffect(() => {
    if (isExpiredPwd || isNotActivatedAccount || state.login.infoMessage.length > 0) {
      hiddenLoginButton(true);
    } else hiddenLoginButton(false);
  }, [isExpiredPwd, isNotActivatedAccount, state?.login?.infoMessage]);

  const renderMessage = () => {
    let content;
    switch (true) {
      case isExpiredPwd:
        content = (
          <div className="login__feedback alert-danger" data-testid="login__error-feedback">
            Conformément à nos règles de sécurité, votre mot de passe doit être renouvelé
            périodiquement. Un email vous permettant de définir un nouveau mot de passe vient de
            vous être envoyé.
          </div>
        );
        break;
      case isNotActivatedAccount:
        content = (
          <div className="login__feedback alert-danger" data-testid="login__error-feedback">
            {state.login.errorMessage}&nbsp;
            <a
              id="login__link-forgot-pwd__error-message"
              data-testid="login__forgot-password-link"
              onClick={() => dispatch(resetPassword(state.user.email))}
            >
              cliquer ici
            </a>
          </div>
        );
        break;
      case isBanAccount:
        content = (
          <div className="login__feedback alert-danger" data-testid="login__error-feedback">
            {state.login.errorMessage}
          </div>
        );
        break;
      case state?.login?.errorMessage?.length > 0 &&
        (!isExpiredPwd || !isNotActivatedAccount || !isBanAccount):
        content = (
          <div className="login__feedback alert-danger" data-testid="login__error-feedback">
            {state.login.errorMessage}
          </div>
        );
        break;
      case state?.login?.infoMessage?.length > 0:
        content = (
          <div className="login__feedback alert-success" data-testid="login__info-feedback">
            {state.login.infoMessage}
          </div>
        );
        break;
      case state?.login?.generalErrorMessage?.length > 0:
        content = (
          <div
            className="login__redirect-feedback alert-danger"
            data-testid="login__redirect-feedback"
          >
            {state.login.generalErrorMessage}
          </div>
        );
        break;

      default:
        content = null;
    }
    return content;
  };

  return <>{renderMessage()}</>;
};

CatalizrLoginMessage.defaultProps = {};

CatalizrLoginMessage.propTypes = {
  hiddenLoginButton: PropTypes.func.isRequired,
};
export default CatalizrLoginMessage;
