import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Checkbox as BaseUiCheckbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import FieldWrapper from '../FieldWrapper';

// Styles
import styles from './styles';

const Checkbox = props => {
  const checkboxOverrides = props.tableCell ? styles.checkboxForTable : styles.checkbox;
  const checkboxDiv = (
    <div className="checkbox-baseui">
      <BaseUiCheckbox
        autoFocus={props.autoFocus}
        checked={props.checked}
        checkmarkType={props.checkmarkType}
        disabled={props.disabled}
        error={props.invalid}
        labelPlacement={props.labelPlacement}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        overrides={checkboxOverrides}
      >
        {props.textLabel && (
          <span className={props.disabled ? `label--disabled` : `label`}>{props.textLabel}</span>
        )}
      </BaseUiCheckbox>
    </div>
  );
  if (!props.label && !props.textLabel) {
    return checkboxDiv;
  }
  return (
    <FieldWrapper
      className={'input'}
      data-testid={props.dataTestId}
      error={props.error}
      hasTooltip={props.hasTooltip}
      label={props.label}
      tooltipText={props.tooltipText}
    >
      {checkboxDiv}
    </FieldWrapper>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  hasTooltip: false,
  checked: false,
  tableCell: false,
  labelPlacement: LABEL_PLACEMENT.right,
};

Checkbox.propTypes = {
  autoFocus: PropTypes.bool,
  checked: PropTypes.bool,
  checkmarkType: PropTypes.oneOf(Object.values(STYLE_TYPE)),
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  hasTooltip: PropTypes.bool,
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(Object.values(LABEL_PLACEMENT)),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  textLabel: PropTypes.string,
  tooltipText: PropTypes.string,
  tableCell: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default Checkbox;
