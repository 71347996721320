import dayjs from 'dayjs';

export function dateChecker(dateValue) {
  if (!dateValue) {
    return { level: 'error', msg: 'Veuillez remplir ce champ', inError: true };
  } else if (!dayjs(dateValue, 'DD/MM/YYYY').isValid()) {
    return {
      level: 'error',
      msg: 'Veuillez entrer une date valide',
      inError: true,
    };
  }
  return { level: '', msg: '', inError: false };
}
