import dayjs from 'dayjs';

// Helpers
import * as CommonAction from './invest-details-common.actions';
import { isFeatureEnabled, isNotBlank } from '../../../shared/utils/CatalizrUtils';
import {
  dispatchChangeToInvestDetailsForm,
  formatFloatValue,
} from '../../../shared/utils/CatalizrFormUtils';
import {
  OPERATION_TYPE_REPURCHASE,
  OPERATION_TYPE_DISPOSAL,
} from '../../../shared/utils/CatalizrConstants';
import { isCompanyFinalStage, isUserCompany } from '../../../shared/utils/JourneyUtils';

// Actions
import {
  initInvestDetailsWithCompanyData,
  initInvestDetailsWithCompanyKindProviderData,
} from './invest-details-company.actions';
import { initInvestDetailsWithInvestorData } from './invest-details-investor.actions';
import { initInvestDetailsWithFinancialBrokerData } from './invest-details-financial-broker.actions';
import { resetCompanyData } from '../../../shared/actions/actions/company.actions';
import { isOwnerShipValidityDate } from '../../../shared/utils/form-validation';
import {
  checkDisplayRealOrEstimateDate,
  privateEquity,
} from '../../../shared/utils/OperationUtils';
import {
  INVESTOR_HAS_WORKED_ENOUGH_IN_AN_INVESTMENT_POSITION,
  INVESTOR_HOLDING_ENOUGH_FINANCIAL_INSTRUMENT,
  INVESTOR_HOLDING_ENOUGH_FINANCIAL_INSTRUMENT_PORTFOLIO,
  INVESTOR_PROFESSIONAL_WITHIN_L_533_16_AND_D_533_11,
  INVESTOR_SUBSCRIPTION_ACQUISITION_CARRIED_OUT_IN_HIS_NAME_AND_ON_HIS_BEHALF,
  INVESTOR_WHOSE_INITIAL_SUBSCRIPTION_AT_LEAST_HUNDRED_THOUSAND_EUROS,
  INVESTOR_WISHING_CLASSIFIED_PROFESSIONAL,
  TO_THE_MANAGER_SERVICES_RELATED_TO_MANAGEMENT_OF_FUND,
} from '../../../shared/actions/const/investor.const';
import { FEATURE_FLAG } from '../../../shared/utils/featureFlag';

// --------------------------------------------------------------------------------------------------------
// DATA INITIALIZATION
// --------------------------------------------------------------------------------------------------------

/**
 * Initializes and dispatch the investment data to redux and the redux-form
 * @param {object} investment the investment data (investmentLink if the investment is not yet created)
 * @param {func} dispatch the redux dispatch function
 */
export const initInvestDetailsWithTheInvestment = (investment, isDraftMode = false, dispatch) => {
  // check if the investment exists => initialize & dispatch data
  if (investment.id) {
    dispatch(CommonAction.setInvestmentData(investment));

    // Formatting date before initialize form
    if (investment.ownership_date && investment.ownership_date !== '') {
      investment.ownership_date = isOwnerShipValidityDate(investment.ownership_date)
        ? dayjs(investment.ownership_date).format('MM/DD/YYYY')
        : null;
    }

    if (investment.payment_date && investment.payment_date !== '') {
      investment.payment_date = dayjs(investment.payment_date).format('DD/MM/YYYY');
    }

    if (investment.effective_date_of_disposal && investment.effective_date_of_disposal !== '') {
      investment.effective_date_of_disposal = dayjs(investment.effective_date_of_disposal).format(
        'DD/MM/YYYY',
      );
    }

    if (investment.cfi_code) {
      investment.company.cfi_code = investment.cfi_code;
    }

    // prefill in case of draft mode
    if (isDraftMode) {
      investment.company = {
        ...investment.company,
        user: {
          email: investment.company.boss.email,
        },
      };
    }

    dispatchChangeToInvestDetailsForm('investment', investment, dispatch);
    initInvestDetailsWithCompanyData(investment, dispatch);
    initInvestDetailsWithCompanyKindProviderData(investment, dispatch);
    initInvestDetailsWithInvestorData(investment, dispatch);
    initInvestDetailsWithFinancialBrokerData(investment, dispatch);
  } else {
    // it is a transmit operation => reset data than can be contained into redux (dashboard)
    dispatch(resetCompanyData());
    // retrieving investor datas from its email if he exists
    initInvestDetailsWithInvestorData(investment, dispatch);
    // init legal forms and boss statuses dropdowns
    dispatch(CommonAction.initLegalFormsAndBossStatuses());
  }
};

/**
 * Initializes and dispatch the investor statuses data to redux and the redux-form
 * @param {object} investment the investment data
 * @param {func} dispatch the redux dispatch function
 */
export const initInvestorStatuses = (investment, dispatch) => {
  let newInvestorStatusesChecked = [];
  if (investment.investor_professional_within_l_53_16_and_d_533_11) {
    newInvestorStatusesChecked = [
      ...newInvestorStatusesChecked,
      INVESTOR_PROFESSIONAL_WITHIN_L_533_16_AND_D_533_11,
    ];
  }
  if (investment.investor_subscription_acquisition_carried_out_in_his_name) {
    newInvestorStatusesChecked = [
      ...newInvestorStatusesChecked,
      INVESTOR_SUBSCRIPTION_ACQUISITION_CARRIED_OUT_IN_HIS_NAME_AND_ON_HIS_BEHALF,
    ];
  }

  if (investment.to_the_manager_services_related_to_management_of_fund) {
    newInvestorStatusesChecked = [
      ...newInvestorStatusesChecked,
      TO_THE_MANAGER_SERVICES_RELATED_TO_MANAGEMENT_OF_FUND,
    ];
  }

  if (investment.investor_whose_initial_subscription_at_least_hundred) {
    newInvestorStatusesChecked = [
      ...newInvestorStatusesChecked,
      INVESTOR_WHOSE_INITIAL_SUBSCRIPTION_AT_LEAST_HUNDRED_THOUSAND_EUROS,
    ];
  }

  dispatchChangeToInvestDetailsForm(
    'investmentReglementary.investorStatusesChecked',
    newInvestorStatusesChecked,
    dispatch,
  );
};

/**
 * Initializes and dispatch the investor categorization data to redux and the redux-form
 * @param {object} investment the investment data
 * @param {func} dispatch the redux dispatch function
 */
export const initInvestorCategorization = (investment, dispatch) => {
  if (investment.investor_wishing_classified_professionnal) {
    dispatchChangeToInvestDetailsForm(
      'investmentReglementary.investorCategorization',
      INVESTOR_WISHING_CLASSIFIED_PROFESSIONAL,
      dispatch,
    );

    let checkboxesChecked = [];
    if (investment.investor_holding_enough_financial_instrument_portfolio) {
      checkboxesChecked = [
        ...checkboxesChecked,
        INVESTOR_HOLDING_ENOUGH_FINANCIAL_INSTRUMENT_PORTFOLIO,
      ];
    }

    if (investment.investor_holding_enough_financial_investment) {
      checkboxesChecked = [...checkboxesChecked, INVESTOR_HOLDING_ENOUGH_FINANCIAL_INSTRUMENT];
    }

    if (investment.investor_has_worked_enough_in_an_investment_position) {
      checkboxesChecked = [
        ...checkboxesChecked,
        INVESTOR_HAS_WORKED_ENOUGH_IN_AN_INVESTMENT_POSITION,
      ];
    }

    dispatchChangeToInvestDetailsForm(
      'investmentReglementary.investorCategorizationCheckedCriteria',
      [
        {
          categoryInvestor: INVESTOR_WISHING_CLASSIFIED_PROFESSIONAL,
          checkboxesChecked,
        },
      ],
      dispatch,
    );
  }
};

// --------------------------------------------------------------------------------------------------------
// DATA CREATION & UPDATE
// --------------------------------------------------------------------------------------------------------

/**
 * Build an object containing the investment data to send to the validate function (API)
 * @param {object} investmentDataFromForm the investment data contained in the form
 * @param {object} investmentFormRedux the investment data stored into redux
 * @param {object} sellerData optional parameter - object containing the seller data of a repurchase operation
 * @param {array} investorsDatas the investors form data
 */
export const buildInvestmentData = (
  investmentDataFromForm,
  investmentFormRedux,
  sellerData = null,
) => {
  const investmentData = {
    portfolio_type: investmentFormRedux.currentProduct,
    operation_type: investmentFormRedux.currentInvestmentType,
    category: investmentDataFromForm.category,
    part_type: investmentDataFromForm.part_type,
    fund_name: investmentDataFromForm.fund_name,
    fund_isin: investmentDataFromForm.fund_isin,
    part_currency: investmentDataFromForm.part_currency,
    nominative_status: investmentDataFromForm.nominative_status,
    compartment: investmentDataFromForm.compartment,
    investmentDisposalMode: investmentFormRedux.investmentDisposalMode,
    distribution_fee: investmentDataFromForm.distribution_fee,
    distribution_fee_bank_name: investmentDataFromForm.distribution_fee_bank_name,
    investment_origins: investmentDataFromForm.investment_origin
      ? [
          {
            investment_origin: investmentDataFromForm.investment_origin,
            email: investmentFormRedux.investor_email || investmentFormRedux.first_investor_email,
          },
        ]
      : [],
    share_premium: investmentDataFromForm.share_premium,
  };

  // if repurchase, send seller data as custom1 in investment object
  if (sellerData && OPERATION_TYPE_REPURCHASE === investmentFormRedux.currentInvestmentType) {
    investmentData.custom1 = sellerData;
  }

  // handle part_amount data if provided
  if (investmentDataFromForm.part_amount) {
    investmentData.part_amount = formatFloatValue(investmentDataFromForm.part_amount);
  } else if (investmentFormRedux.part_amount) {
    investmentData.part_amount = null;
  }

  // handle nb_part data if provided
  if (investmentDataFromForm.nb_part) {
    investmentData.nb_part = formatFloatValue(investmentDataFromForm.nb_part);
  } else if (investmentFormRedux.nb_part) {
    investmentData.nb_part = null;
  }

  // handle part_amount data if provided
  if (investmentDataFromForm.share_premium) {
    investmentData.share_premium = formatFloatValue(investmentDataFromForm.share_premium);
  } else if (investmentFormRedux.share_premium) {
    investmentData.share_premium = null;
  }

  // send the open_date and the planned_close_date if provided
  // (date format is reversed for back - end > to fix later)
  if (investmentFormRedux.currentInvestmentType !== OPERATION_TYPE_DISPOSAL) {
    _addDateToPayload(investmentDataFromForm, investmentData, 'open_date');
    _addDateToPayload(investmentDataFromForm, investmentData, 'planned_close_date');
  }

  if (isCompanyFinalStage()) {
    _addDateToPayload(investmentDataFromForm, investmentData, 'ownership_date');
    if (investmentDataFromForm.part_level !== null) {
      investmentData.part_level = investmentDataFromForm.part_level;
    }
  }

  if (checkDisplayRealOrEstimateDate()) {
    investmentData.real_estimated_invest_date = investmentDataFromForm.real_estimated_invest_date;
  }

  // specific data asked for disposal operations
  // data retrieved from the investment object because these datas are attached to the operation
  if (OPERATION_TYPE_DISPOSAL === investmentFormRedux.currentInvestmentType) {
    investmentData.sale_type = investmentDataFromForm.sale_type;
    investmentData.payment_method = investmentDataFromForm.payment_method;
    investmentData.charged_account = investmentDataFromForm.charged_account;
    // dirty fix, api not processing empty value so we're sending an empty space to force value update
    investmentData.additional_info = isNotBlank(investmentDataFromForm.additional_info)
      ? investmentDataFromForm.additional_info
      : ' ';

    _addDateToPayload(investmentDataFromForm, investmentData, 'payment_date');
    _addDateToPayload(investmentDataFromForm, investmentData, 'effective_disposal_date');
  }

  _addDateToPayload(investmentDataFromForm, investmentData, 'first_call_for_funds_date');

  if (investmentDataFromForm.custom_fields) {
    investmentData.custom_fields = investmentDataFromForm.custom_fields;
  }

  return investmentData;
};

/**
 * Build an object containing the investmentReglementary data to send to the validate function (API)
 * @param {object} formData the form datas
 */
export const buildInvestmentReglementaryData = formData => {
  const investmentReglementaryData = {
    investor_confirmation: formData.investmentReglementary?.investor_confirmation,
    investor_investment_ownership:
      formData.investmentReglementary?.investor_investment_ownership ===
      'investor_investment_ownership_on',
  };

  if (privateEquity() && !isUserCompany()) {
    if (isFeatureEnabled(FEATURE_FLAG.display_investor_categorization)) {
      investmentReglementaryData.investorCategory =
        +formData?.investmentReglementary?.investorCategorization;
      investmentReglementaryData.investorCategorizationCheckedCriteria =
        +formData?.investmentReglementary?.investorCategorization === 0
          ? formData?.investmentReglementary?.investorCategorizationCheckedCriteria[
              +formData?.investmentReglementary?.investorCategorization
            ].checkboxesChecked
          : [];
    }
    if (isFeatureEnabled(FEATURE_FLAG.display_investor_status)) {
      investmentReglementaryData.investorStatusesChecked =
        formData?.investmentReglementary?.investorStatusesChecked;
    }
  }

  return investmentReglementaryData;
};

/**
 * Add the given date (dateField) to the payload only if valid
 * @param {object} investmentDataFromForm data coming from the redux-form
 * @param {object} payload the object to enrich
 * @param {string} dateField name of the property to process
 */
const _addDateToPayload = (investmentDataFromForm, payload, dateField) => {
  const dateFromForm = investmentDataFromForm[dateField];

  // only if valid date given
  if (dateFromForm && dayjs(dateFromForm).isValid()) {
    payload[dateField] = dayjs(dateFromForm).format('MM-DD-YYYY');
  } else if (dateFromForm && dayjs(dateFromForm, 'MM-DD-YYYY').isValid()) {
    payload[dateField] = dayjs(dateFromForm, 'MM-DD-YYYY').format('MM-DD-YYYY');
  } else if (dayjs.isDayjs(dateFromForm) && dateFromForm) {
    payload[dateField] = dateFromForm.format('MM-DD-YYYY');
  } else if (typeof dateFromForm === 'object' && dateFromForm) {
    payload[dateField] = dayjs(dateFromForm).format('MM-DD-YYYY');
  } else if (!dateFromForm) {
    payload[dateField] = undefined;
  }
};
