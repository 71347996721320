import * as investorConst from '../const/investor.const';
import { getCurrentPortfolioType } from '../../utils/CatalizrUtils';

export const resetInvestorState = () => ({ type: investorConst.RESET_STATE });
export const resetInvestorOnEmailDoesNotExist = () => ({
  type: investorConst.RESET_INVESTOR_ON_EMAIL_DOES_NOT_EXIST,
});
export const setInvestorEmail = email => ({ type: investorConst.SET_INVESTOR_EMAIL, email });
export const initInvestor = investor => {
  return {
    type: investorConst.INIT_INVESTOR,
    investor: { ...investor, currentPortfolioType: getCurrentPortfolioType() },
  };
};
