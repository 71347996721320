/* eslint-disable jsx-a11y/anchor-is-valid */
import { lazy, useMemo, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

// Components
import Input from '../../../components/ReactHookFormFields/Input';
import Checkbox from '../../../components/ReactHookFormFields/Checkbox/index';
import LazyWrapper from '../../../components/LazyWrapper/index';
import Button from '../../../components/Button/index';
import SpinnerWrapper from '../../../components/Spinner';

// Lazy components
const IconQuestionmark = lazy(() => import('../../../iconsPictos/iconQuestionMark/'));

// Actions
import { resetPassword, setErrorMessage } from '../actions/login.actions';

// UI
import '../style.scss';

const LoginForm = ({
  step,
  handleChange,
  userEmail,
  rememberEmail,
  handleRememberEmailChange,
  isHiddenLogButton,
}) => {
  const dispatch = useDispatch();
  const { control, formState } = useFormContext();

  const iconQuestionMark = (
    <LazyWrapper>
      <IconQuestionmark
        width="23px"
        height="23px"
        title="forget password"
        alt="forget password"
        fill={true}
      />
    </LazyWrapper>
  );

  const memoizedForgetLinkPassword = useMemo(() => {
    return (
      <Fragment>
        {iconQuestionMark}
        &nbsp;
        <a
          id="login__link-forgot-pwd"
          role="button"
          data-testid="login__forgot-password-link"
          onClick={() =>
            userEmail
              ? dispatch(resetPassword(userEmail))
              : dispatch(
                  setErrorMessage(
                    'Merci de renseigner votre email pour réinitialiser votre mot de passe',
                  ),
                )
          }
        >
          Mot de passe oublié ?
        </a>
      </Fragment>
    );
  }, [userEmail]);

  return (
    <div className="login__container__form__group">
      <div className="login__container__form__group__item">
        <Controller
          control={control}
          name="email"
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error, invalid },
          }) => {
            return (
              <Input
                id="login-form-email"
                name={name}
                error={error}
                invalid={invalid}
                clearable={true}
                type="email"
                label="Renseignez votre email"
                placeholder="Renseignez votre email"
                value={value}
                onBlur={onBlur}
                onChange={e => {
                  handleChange(name, e, onChange, step);
                }}
                onKeyDown={e => {
                  if (e.key === ' ') {
                    e.preventDefault();
                  }
                }}
              />
            );
          }}
        />
        <div className="login__container__form__group__item__checkbox">
          <Checkbox
            name="remenber-login-email"
            checked={rememberEmail}
            onChange={handleRememberEmailChange}
            labelPlacement="right"
            textLabel={'Se souvenir de mon email'}
          />
        </div>
      </div>

      <div className="login__container__form__group__item">
        {step === 2 && (
          <Controller
            control={control}
            name="password"
            render={({
              field: { name, onBlur, onChange, value },
              fieldState: { error, invalid },
            }) => {
              return (
                <Input
                  id="login-form-password"
                  name={name}
                  clearable={true}
                  error={error}
                  invalid={invalid}
                  type="password"
                  label="Mot de passe"
                  placeholder="Mot de passe"
                  value={value}
                  onBlur={onBlur}
                  onChange={e => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        )}
        <div className="login__container__form__group__item__link">
          {step === 2 && memoizedForgetLinkPassword}{' '}
        </div>
      </div>
      <div
        className={`login__container__form__group__actions${isHiddenLogButton ? '__hidden' : ''}`}
      >
        <SpinnerWrapper isLoading={formState.isLoading}>
          <Button
            data-testid="login__form-button"
            type="submit"
            design="primary"
            cssId="login__form-button"
            disabled={!formState.isValid || formState.isSubmitting}
            text={
              formState.isSubmitting ? 'Chargement...' : step === 1 ? 'Continuer' : 'Se connecter'
            }
          />
        </SpinnerWrapper>
      </div>
    </div>
  );
};

LoginForm.defaultProps = {};

LoginForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleRememberEmailChange: PropTypes.func.isRequired,
  isHiddenLogButton: PropTypes.bool.isRequired,
  rememberEmail: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  userEmail: PropTypes.string,
};

export default LoginForm;
