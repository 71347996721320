import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../../../components/Button';
import SpinnerWrapper from '../../../components/Spinner';
import InputField from '../../../components/InputField';

// Utils
import { isEmailAddressValid } from '../../../shared/utils/helper';

import '../styles.scss';

const handleFormSubmitCreation = (handleForm, history, options) => {
  const email = document.getElementById('registration-form-email').value.trim();
  handleForm(email, history, options);
};

const RegistrationForm = ({ user, registration, handleForm, setUserEmail, history, bank }) => {
  const registrationButton = (
    <Button
      text="Créer un compte"
      design="primary"
      cssId="registration__form-button"
      type="submit"
      disabled={isEmailAddressValid(user.email) === false}
    />
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleFormSubmitCreation(handleForm, history);
      }}
      className="registration__form"
      data-testid="registration__form"
    >
      {bank.logo && (
        <div>
          <img
            className="bank-logo"
            src={`${process.env.REACT_APP_BACKEND_URL}/api${bank.logo}`}
            alt="bank logo"
          />
        </div>
      )}

      <span>
        Cette page vous permet de créer un accès à Catalizr. Vous devez disposer d’une adresse email
        professionnelle.
      </span>
      {/* EMAIL INPUT FIELD */}
      <InputField
        topClass="registration__input-field form-group"
        type="email"
        cssId="registration-form-email"
        label="Identifiant ou email"
        placeholder="Ex : exemple@email.com"
        onChange={event => {
          event.target.value = event.target.value.toLowerCase();
          setUserEmail(event.target.value);
        }}
        restrictTo="[^<>()\[\]\\,;:\s]"
        initialValue={user.email}
      />

      {/* POTENTIAL ERROR MESSAGE */}
      {registration.errorMessage ? (
        <div
          className="registration__feedback alert-danger"
          data-testid="registration__error-feedback"
        >
          {registration.errorMessage}
        </div>
      ) : null}

      {/* POTENTIAL INFORMATION MESSAGE */}
      {registration.infoMessage ? (
        <div
          className="registration__feedback alert-success"
          data-testid="registration__info-feedback"
        >
          {registration.infoMessage}
        </div>
      ) : (
        <div className="registration__main__footer">
          {registration.loadingInProgress ? (
            <SpinnerWrapper isLoading={registration.loadingInProgress} />
          ) : (
            registrationButton
          )}
        </div>
      )}
    </form>
  );
};

RegistrationForm.propTypes = {
  // TODO comment this
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  registration: PropTypes.shape({
    loadingInProgress: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    infoMessage: PropTypes.string.isRequired,
  }).isRequired,
  // trigger actions
  setUserEmail: PropTypes.func.isRequired,
  handleForm: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  bank: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default RegistrationForm;
