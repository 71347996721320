import dayjs from 'dayjs';
import _ from 'lodash';
import { isAxiosError } from 'axios';
import { utils, Enum } from '@utocat/catalizr-const-sharing-lib';

// Const
import * as CommonAction from './invest-details-common.actions';
import * as OperationUtils from '../../../shared/utils/OperationUtils';
import { FAKE_IBAN_PATTERN } from '../../../shared/utils/CatalizrConstants';

// Api
import {
  getInvestorByEmailAndUuidLink,
  getInvestorByEmail,
} from '../../../shared/api/investor.api';

// Actions
import {
  dispatchChangeToInvestDetailsForm,
  INVEST_DETAILS_REDUX_OBJECTS_NAMING,
} from '../../../shared/utils/CatalizrFormUtils';
import {
  getCurrentPortfolioType,
  selectDefaultBankName,
} from '../../../shared/utils/CatalizrUtils';

// Utils
import { create, increase, isPortfolioMultiple } from '../../../shared/utils/OperationUtils';
import { resetInvestorOnEmailDoesNotExist } from '../../../shared/actions/actions/investor.actions';
import { isUserInvestor } from '../../../shared/utils/JourneyUtils';
import { getTranslatedFranceCountry } from '../../../shared/utils/country-utils';

// --------------------------------------------------------------------------------------------------------
// DATA INITIALIZATION
// --------------------------------------------------------------------------------------------------------

/**
 * Dispatches the investor data to redux and the redux-form
 * @param {string} investment the full investment data (containing a investor property)
 * @param {func} dispatch the redux dispatch function
 */
export const initInvestDetailsWithInvestorData = async (investment = null, dispatch) => {
  const investorData = investment ? investment.investors?.[0] : null;
  const coInvestorData =
    Array.isArray(investment?.investors) && investment.investors.length > 1
      ? investment.investors[1]
      : null;
  if (investorData) {
    investorData.ppe = investorData?.ppe ? 'ppe_on' : 'ppe_off';
    investorData.ppe_partner = investorData?.ppe_partner ? 'ppe_partner_on' : 'ppe_partner_off';
    investorData.ppe_family = investorData?.ppe_family ? 'ppe_family_on' : 'ppe_family_off';
    investorData.investor_investment_ownership = investorData?.investor_investment_ownership
      ? 'investor_investment_ownership_on'
      : 'investor_investment_ownership_off';
  }
  if (coInvestorData && investorData) {
    coInvestorData.iban = investorData.iban;
    coInvestorData.bank_name = investorData.bank_name;
  }
  if (investorData && dispatch) {
    dispatchToRedux(
      dispatch,
      investorData,
      INVEST_DETAILS_REDUX_OBJECTS_NAMING.investor,
      investment,
    );
  }
  if (coInvestorData && dispatch) {
    dispatchToRedux(
      dispatch,
      coInvestorData,
      INVEST_DETAILS_REDUX_OBJECTS_NAMING.coInvestor2,
      investment,
    );
  }
  // init seller data for repurchase only if data
  if (OperationUtils.repurchase() && investment.custom1) {
    const sellerData = investment.custom1;
    dispatchChangeToInvestDetailsForm('seller', sellerData, dispatch);
  }
  if (!isUserInvestor() && investment.investor_email) {
    dispatch(getInvestorExistByEmail(investment.investor_email, investment));
  }
};

/**
 * Retrieves an investor by its email and dispatch the result to redux and redux-form
 * @param {string} investorEmail
 * @param {object} investment for init in case of new portfolio type not registered
 * @param {string} reduxObjectName
 * @param {string} investment_link_id
 */
export const getInvestorExistByEmail = (
  investorEmail,
  investment = null,
  reduxObjectName = INVEST_DETAILS_REDUX_OBJECTS_NAMING.investor,
  investmentUuid = null,
) => {
  const firstInvestor = _.get(investment || {}, INVEST_DETAILS_REDUX_OBJECTS_NAMING.investor);
  const currentInvestor = _.get(investment || {}, reduxObjectName);
  const ptfData = {
    portfolio_type: getCurrentPortfolioType(),
    iban: firstInvestor?.iban,
    trading_account_number: firstInvestor?.trading_account_number,
  };

  const buildData = investorData => ({
    ...investorData,
    bank_name: investorData?.bank_name ?? selectDefaultBankName(),
    ppe: investorData?.ppe ? 'ppe_on' : 'ppe_off',
    ppe_partner: investorData?.ppe_partner ? 'ppe_partner_on' : 'ppe_partner_off',
    ppe_family: investorData?.ppe_family ? 'ppe_family_on' : 'ppe_family_off',
    investor_investment_ownership: investorData?.investor_investment_ownership
      ? 'investor_investment_ownership_on'
      : 'investor_investment_ownership_off',
    registered_country: getTranslatedFranceCountry(),
    ibans:
      reduxObjectName === INVEST_DETAILS_REDUX_OBJECTS_NAMING.coInvestor2 ? [ptfData] : undefined,
  });

  const handleError = (err, dispatch) => {
    if (reduxObjectName === INVEST_DETAILS_REDUX_OBJECTS_NAMING.investor) {
      dispatch(resetInvestorOnEmailDoesNotExist());
    }
    if (currentInvestor?.is_identity_confirmed === 1) {
      dispatchToRedux(
        dispatch,
        {
          email: investorEmail,
          ibans: [ptfData],
        },
        reduxObjectName,
        investment,
      );
    }
    if (!isAxiosError(err) || (isAxiosError(err) && err.response.status !== 404)) {
      throw err;
    }
  };

  return async dispatch => {
    try {
      let investorData;
      if (isUserInvestor() && isPortfolioMultiple()) {
        ({ data: investorData } = await getInvestorByEmailAndUuidLink(
          investmentUuid,
          investorEmail,
        ));
      } else if (!isUserInvestor()) {
        ({ data: investorData } = await getInvestorByEmail(investorEmail));
      } else {
        return;
      }
      const data = buildData(investorData);
      dispatchToRedux(dispatch, data, reduxObjectName, investment);
    } catch (err) {
      handleError(err, dispatch);
    }
  };
};

// --------------------------------------------------------------------------------------------------------
// DATA CREATION & UPDATE
// --------------------------------------------------------------------------------------------------------

/**
 *
 * @param {object} investorDataFromForm the investor data contained in the form
 * @param {object} investmentFormRedux
 * @param {object} investmentDataFromForm the investment data contained in the form
 */
export const buildInvestorData = (investorDataFromForm, investmentFormRedux) => {
  // common investor data

  let investorData = {
    title: investorDataFromForm.title,
    name: investorDataFromForm.name,
    surname: investorDataFromForm.surname,
    address: investorDataFromForm.address || '',
    zip: investorDataFromForm.zip || '',
    city: investorDataFromForm.city || '',
    country: investorDataFromForm.country || '',
    registered_country: investorDataFromForm.registered_country,
    email: investorDataFromForm.email,
    bank_name:
      typeof investorDataFromForm.bank_name === 'object' && !investorDataFromForm.bank_name.length
        ? ''
        : investorDataFromForm.bank_name,
    iban: investorDataFromForm.iban || '',
    trading_account_number: investorDataFromForm.trading_account_number
      ? investorDataFromForm.trading_account_number
      : '',
    portfolio_type: investmentFormRedux.portfolio_type,
    ppe: investorDataFromForm.ppe === 'ppe_on',
    ppe_partner: investorDataFromForm.ppe_partner === 'ppe_partner_on',
    ppe_function: investorDataFromForm.ppe_function,
    ppe_country: investorDataFromForm.ppe_country,
    ppe_family: investorDataFromForm.ppe_family === 'ppe_family_on',
    family_ppe_function: investorDataFromForm.family_ppe_function,
    family_ppe_country: investorDataFromForm.family_ppe_country,
    share_capital: investorDataFromForm.share_capital,
    place_of_registration: investorDataFromForm.place_of_registration,
    country_of_tax_residence: investorDataFromForm.country_of_tax_residence,
    tax_residence: investorDataFromForm.tax_residence,
    tax_regime: investorDataFromForm.tax_regime,
    phone: investorDataFromForm.phone,
  };

  // extra investor datas linked to display.investor_birth feature flag
  if (investorDataFromForm.birth_date && investorDataFromForm.birth_city) {
    _addDateToPayload(investorDataFromForm, investorData, 'birth_date');
    investorData.birth_city = investorDataFromForm.birth_city;
  }

  // extra investor datas linked to display.occupation
  if (investorDataFromForm.occupation) {
    investorData.occupation = investorDataFromForm.occupation;
  }

  // extra investor datas linked to display.giin_number
  if (investorDataFromForm.giin_number) {
    investorData.giin_number = investorDataFromForm.giin_number;
  }

  // extra investor data for CTO legal person only if investor is a legal person
  if (
    utils.users.isLegalEntity(investorDataFromForm.title) &&
    Enum.Accounts.PortfolioType.CTO === investmentFormRedux.portfolio_type
  ) {
    investorData.siren = investorDataFromForm.siren;
    investorData.company_name = investorDataFromForm.company_name;
  }

  //FIXME: pb si investor personne morale deja en db et que l'utilisateur reprend une personne physique (ex: Monsieur)
  // les données siren et company_name seront tjs stockées en bdd et se seront pas reset

  return investorData;
};

/**
 *
 * @param {object} investorsDataFromForm form investors datas
 * @param {object} investmentFormRedux form investment datas
 * @param {object} investmentDataFromForm redux investment datas
 */
export const buildInvestorsData = (
  investorsDataFromForm,
  investmentFormRedux,
  investmentDataFromForm,
) => {
  const investors = investorsDataFromForm.map(investor =>
    buildInvestorData(investor, investmentFormRedux, investmentDataFromForm),
  );
  if (investors[1]) {
    investors[1].ppe_country = investors[0].ppe_country;
    investors[1].ppe_family = investors[0].ppe_family;
    investors[1].ppe_function = investors[0].ppe_function;
    investors[1].family_ppe_country = investors[0].family_ppe_country;
    investors[1].family_ppe_function = investors[0].family_ppe_function;
    investors[1].ppe_partner = investors[0].ppe;
    investors[1].ppe = investors[0].ppe_partner;
    investors[1].iban = investors[0].iban;
    investors[1].bank_name = investors[0].bank_name;
  }

  return investors;
};

export const buildInvestorCompanyDataToDispatch = company => {
  const companyData = {
    id: company.id,
    siren: company.siren,
    company_name: company.name,
    lastname: company.lastname,
    surname: company.surname,
    boss: company.boss,
    user: company.user,
    address: company.address,
    country: company.country,
    registered_country: company.registered_country,
    city: company.city,
    zip: company.zip,
    email: company.email,
    legal_form: company.legal_form,
    bank_name: company.bank_name,
    fundraising_default: company.fundraising_default,
    in_progress: company.in_progress,
    data_from_insee: company.data_from_insee,
    eligibility: company.eligibility,
    is_ceased: company.is_ceased,
    eligibility_comment: company.eligibility_comment,
  };

  // dispatch the company iban only if not a fake generated (fake company iban are generated for repurchase operations, only for new company)
  if (typeof company.iban === 'string' && !company.iban.startsWith(FAKE_IBAN_PATTERN)) {
    companyData.iban = company.iban;
  }

  // if the company changes in the form (click on a company suggestion), we empty the iban field
  // we empty IBAN only if it is required in form (increase and create only for now)
  if ((increase() || create()) && !company.user) {
    companyData.iban = '';
  }

  return companyData;
};

// -----------------------------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------------------------

/**
 * Dispatch the provided investor data to redux and redux-form
 * @param {object} investment current investment in case of new portfolio type not registerd
 * @param {object} investorData the investor data returned by the API call
 * @param {string} reduxObjectName the name of the redux form object to dispatch data
 * @param {function} dispatch the redux dispatch function
 */
export const dispatchToRedux = (
  dispatch,
  investorData,
  reduxObjectName = INVEST_DETAILS_REDUX_OBJECTS_NAMING.investor,
  investment = null,
) => {
  // retrieves the IBAN to use in the investor portfolios/accounts
  const ibanAccountToUse = investorData.ibans?.find(
    ibanAccount => ibanAccount.portfolio_type === getCurrentPortfolioType(),
  );
  if (ibanAccountToUse) {
    // reuse registered portfolio
    investorData.iban = ibanAccountToUse.iban;
    investorData.trading_account_number = ibanAccountToUse.trading_account_number;
  } else if (investment) {
    // case of init on a new portfolio not registered yet
    investorData.iban = investment.investor_iban;
    investorData.trading_account_number = investment.investor_trading_account_number;
  } else {
    // clean as switch of investor mail for example
    investorData.iban = '';
    investorData.trading_account_number = '';
  }
  // dispatch investor data to redux
  if (reduxObjectName === INVEST_DETAILS_REDUX_OBJECTS_NAMING.investor) {
    dispatch(CommonAction.setInvestorData(investorData));
  }
  // dispatch investor data to the redux form (invest-details)
  dispatchChangeToInvestDetailsForm(reduxObjectName, investorData, dispatch);
};

const _addDateToPayload = (investorDataFromForm, payload, dateField) => {
  const dateFromForm = investorDataFromForm[dateField];
  const isValidDateFromForm = dateFromForm && dayjs(dateFromForm).isValid();

  // only if valid date given
  if (isValidDateFromForm) {
    payload[dateField] = dayjs(dateFromForm).format('MM-DD-YYYY');
  }

  if (dayjs.isDayjs(dateFromForm) && dateFromForm) {
    payload[dateField] = dateFromForm.format('MM-DD-YYYY');
  }
  if (typeof dateFromForm === 'object' && dateFromForm) {
    payload[dateField] = dayjs(dateFromForm).format('MM-DD-YYYY');
  } else if (!dateFromForm) {
    payload[dateField] = undefined;
  }
};
