// Actions
import {
  getCounters,
  getUserInvestmentsByCurrentTab,
} from '../../common/actions/dashboard.actions';
import { setOriginalJourneyId } from '../../../../redux/actions';
import { clearRedux } from '../../../../shared/actions/actions/common.actions';
import { initViewNewOperation } from '../../Bank/actions/dashboard-bank.actions';
import { getCurrentUserType } from '../../../../shared/utils/JourneyUtils';

export const initView = () => {
  return async dispatch => {
    dispatch(clearRedux(getCurrentUserType()));
    dispatch(setOriginalJourneyId(''));

    // INIT MODAL NEW OPERATION
    dispatch(initViewNewOperation());

    // INIT DASHBOARD OPERATIONS
    const getDashboardCounters = await dispatch(getCounters());
    const getOperation = await dispatch(getUserInvestmentsByCurrentTab());

    Promise.all([getDashboardCounters, getOperation]);
  };
};
