// Const
import * as loginConst from './login.const';
import * as userConst from '../../../shared/actions/const/user.const';

// Actions
import { setJourney } from '../../../redux/actions';
import { getUserFulfilled } from '../../../shared/actions/actions/user.actions';
import { fetchBankKit, fetchUsersBank } from '../../../shared/actions/actions/bank.actions';
import { getAllBanners, clearDraftMode } from '../../../shared/actions/actions/common.actions';

// Api
import { apiRequireJwt, apiResetPassword, getFeatureEnableByUserRole } from '../api/login.api';
import { getBanners } from '../../../shared/api/common.api';

// Utils
import { isUserBank, JOURNEY } from '../../../shared/utils/JourneyUtils';

// Other
import queryString from 'query-string';
import { getStore } from '../../../store';

const submitFormPending = () => ({ type: loginConst.SUBMIT_FORM_PENDING });
const submitFormFulfilled = () => ({ type: loginConst.SUBMIT_FORM_FULFILLED });
const submitFormRejected = () => ({ type: loginConst.SUBMIT_FORM_REJECTED });

const checkFeatureIsActivePending = () => ({ type: loginConst.CHECK_FEATURE_IS_PENDING });
const checkFeatureIsActiveFulfilled = data => ({
  type: loginConst.CHECK_FEATURE_IS_FULFILLED,
  payload: data,
});

export const addThemeToUser = data => ({
  type: userConst.ADD_THEME_TO_USER,
  payload: data,
});

export const checkFeatureIsActive = (feature, role) => async dispatch => {
  dispatch(checkFeatureIsActivePending());
  const response = await getFeatureEnableByUserRole(feature, role);
  dispatch(checkFeatureIsActiveFulfilled(response.data));
};

export const requireJwtPending = () => ({ type: loginConst.REQUIRE_JWT_PENDING });
export const requireJwtFulfilled = () => ({ type: loginConst.REQUIRE_JWT_FULFILLED });
export const requireJwtRejected = payload => ({ type: loginConst.REQUIRE_JWT_REJECTED, payload });

export const resetPassword = email => {
  let params = {};
  return {
    type: loginConst.RESET_PASSWORD,
    payload: apiResetPassword(email, params),
  };
};

export const setIsLoading = () => ({
  type: loginConst.RESET_PASSWORD_PENDING,
});

export const setNewUrl = url => ({
  type: loginConst.SET_NEW_URL,
  url,
});

export const onSubmit = (email, password, history, location, options) => dispatch => {
  dispatch(submitFormPending());

  // for fix security we no longer check if user account exist on db just check get authorized
  apiRequireJwt(email, password, options)
    .then(user => {
      dispatch(requireJwtFulfilled());
      dispatch(clearDraftMode());
      // define which url use according to user type
      const userType = user.data.data.role;
      const homeByUserType = {
        company: '/company/dashboard',
        investor: '/investor/dashboard',
      };
      // If user comes from /login, we redirect him to his dashboard, instead if he comes from an email we keep path
      let url =
        location.pathname === '/login'
          ? homeByUserType[userType] || '/bank/dashboard'
          : location.pathname + location.search;
      if (isUserBank()) {
        dispatch(setJourney(JOURNEY.BANK_COMPLETE));
      }
      const values = queryString.parse(location.search);
      if (values.redirect_url) {
        window.location.assign(values.redirect_url);
      } else {
        dispatch(setNewUrl(url));
        // set type and email of user (header)
        dispatch(getUserFulfilled(user.data.data));
        dispatch(submitFormFulfilled());
        dispatch(fetchBankKit());
        const { theme } = getStore().getState().bank;
        dispatch(addThemeToUser(theme));
        dispatch(fetchUsersBank());
        history.push(url);
      }
    })
    .catch(error => {
      dispatch(requireJwtRejected(error));
      dispatch(submitFormRejected(error));
    });
};

export const initView = (location, history) => {
  return async dispatch => {
    await dispatch({ type: loginConst.INIT_VIEW });
    const getBankDatas = await dispatch(fetchBankKit());
    const bankDatas = getBankDatas.value.data.data;
    // urlRequired is provided for 'cmcic' subdomain and equal '/portal'
    // to prevent users from trying to connect on cmcic.catalizr.eu/login
    if (bankDatas.urlRequired && bankDatas.urlRequired !== location.pathname) {
      history.push(bankDatas.urlRequired);
    }
  };
};

export const setErrorMessage = message => ({
  type: loginConst.SET_ERROR_MESSAGE,
  message,
});

export const setBanners = () => async dispatch => {
  const allBanners = await getBanners();
  if (allBanners && allBanners.data) {
    dispatch(getAllBanners(allBanners.data));
  }
};

/**
 * Action to use to clear the messages related to the login :
 * - infoMessage
 * - errorMessage
 */
export const clearMessages = () => ({ type: loginConst.CLEAR_MESSAGES });
export const displayRedirectedAccessDenied = () => ({ type: loginConst.REDIRECT_ACCESS_DENIED });
export const displayRedirectedUnavailable = () => ({ type: loginConst.REDIRECT_UNAVAILABLE });
