import React from 'react';
import { Enum, utils } from '@utocat/catalizr-const-sharing-lib';

import { intl } from '../../intlConfig';
import CustomHeader from '../../components/Table/components/Headers/CustomHeader';

export const addOptionalMention = (labelOrMessage, params = {}) => {
  if (typeof labelOrMessage === 'string') {
    return intl.formatMessage(
      {
        id: 'form.optional_%field',
        defaultMessage: '{field} (optional)',
      },
      {
        field: labelOrMessage,
      },
    );
  }
  if (typeof labelOrMessage === 'object' && labelOrMessage.id) {
    return intl.formatMessage(
      {
        id: 'form.optional_%field',
        defaultMessage: '{field} (optional)',
      },
      {
        field: intl.formatMessage(labelOrMessage, params),
      },
    );
  }
  // don't known what is it, so return as it
  return labelOrMessage;
};

export const addMentionForCustomField = (labelField, mandatory = false) => {
  return intl.formatMessage(
    {
      id: mandatory ? 'form.mandatory_%field' : 'form.optional_%field',
      defaultMessage: mandatory ? '{field} (mandatory)' : '{field} (optional)',
    },
    {
      field: labelField,
    },
  );
};

export const addMentionForForcedField = (labelOrMessage, forced = false, params = {}) => {
  if (typeof labelOrMessage === 'string') {
    return intl.formatMessage(
      {
        id: forced ? 'form.mandatory_%field' : 'form.recommended_%field',
        defaultMessage: forced ? '{field} (mandatory)' : '{field} (recommended)',
      },
      {
        field: labelOrMessage,
      },
    );
  }
  if (typeof labelOrMessage === 'object' && labelOrMessage.id) {
    return intl.formatMessage(
      {
        id: forced ? 'form.mandatory_%field' : 'form.recommended_%field',
        defaultMessage: forced ? '{field} (mandatory)' : '{field} (recommended)',
      },
      {
        field: intl.formatMessage(labelOrMessage, params),
      },
    );
  }
  // don't known what is it, so return as it
  return labelOrMessage;
};

export const translatePortfolioType = ptfType => {
  if (typeof ptfType === 'string' && ptfType) {
    return intl.formatMessage({
      id: utils.translation.getPortfolioTypeKey(ptfType),
    });
  }
  return ptfType;
};

export const translateOperationType = opType => {
  if (typeof opType !== 'string' || opType === '') {
    return opType;
  }
  const tradKey = utils.translation.getOperationTypeKey(opType);
  if (tradKey !== opType) {
    // verify of trad key has been converted, else not an operation type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  return opType;
};

export const translateOperationTypeTooltip = (opType, description) => {
  if (typeof opType !== 'string' || opType === '') {
    return opType;
  }
  const tradKey = utils.translation.getOperationTypeKey(opType);
  if (tradKey !== opType) {
    // verify of trad key has been converted, else not an operation type
    return intl.formatMessage({
      id: `${tradKey}_TOOLTIP`,
    });
  }
  return description;
};

export const translateOperationStep = opStepCode => {
  if (typeof opStepCode !== 'string' || opStepCode === '') {
    return opStepCode;
  }
  const tradKey = utils.translation.getOperationStepKey(opStepCode);
  if (tradKey !== opStepCode) {
    // verify of trad key has been converted, else not a shareholding movement type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  return opStepCode;
};

export const translateOperationManagementType = managementType => {
  if (typeof managementType !== 'string' || managementType === '') {
    return managementType;
  }
  const tradKey = utils.translation.getOperationManagementTypeKey(managementType);
  if (tradKey !== managementType) {
    // verify of trad key has been converted, else not a shareholding movement type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  return managementType;
};

export const translateShareholdingMovementType = mvtType => {
  if (typeof mvtType !== 'string' || mvtType === '') {
    return mvtType;
  }
  const tradKey = utils.translation.getShareholdingMovementTypeKey(mvtType);
  if (tradKey !== mvtType) {
    // verify of trad key has been converted, else not a shareholding movement type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  return mvtType;
};

export const translateUserRole = userRole => {
  if (typeof userRole !== 'string' || userRole === '') {
    return userRole;
  }
  let tradKey = utils.translation.getUserRoleKey(userRole);
  if (tradKey === userRole) {
    tradKey = utils.translation.getUserRoleKey(userRole.toLowerCase());
  }
  if (tradKey !== userRole) {
    // verify of trad key has been converted, else not an user role type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  return userRole;
};

export const translateEmailStatus = emailStatus => {
  if (typeof emailStatus !== 'string' || emailStatus === '') {
    return emailStatus;
  }
  const tradKey = utils.translation.getEmailStatusKey(emailStatus);
  if (tradKey !== emailStatus) {
    // verify of trad key has been converted, else not an email status
    return intl.formatMessage({
      id: tradKey,
    });
  }
  if (emailStatus === 'none') {
    return intl.formatMessage({
      id: 'enum.email.status.none',
    });
  }
  return emailStatus;
};

export const translateUserTitle = userTitle => {
  switch (userTitle) {
    case Enum.User.Title.MR:
      return intl.formatMessage({ id: 'enum.user.title.MR' });
    case Enum.User.Title.MRS:
      return intl.formatMessage({ id: 'enum.user.title.MRS' });
    case Enum.User.Title.LEGAL_ENTITY:
      return intl.formatMessage({ id: 'enum.user.title.LEGAL_ENTITY' });
    default:
      return userTitle;
  }
};

export const getTableHeaderContent = (column, customSort = false) => {
  let cellContent = column.header;
  if (typeof column.header === 'string') {
    cellContent = intl.formatMessage({
      id: `table.columns.${column.header}`,
    });
  } else if (typeof column.header === 'function') {
    cellContent = column.header(intl);
  }
  if (customSort) {
    let tooltip;
    if (column.tooltip) {
      tooltip = intl.formatMessage({
        id: `tooltip.${column.tooltip}`,
      });
    }
    return options => {
      let sortBy = {
        id: '',
        desc: false,
      };
      if (options.state && Array.isArray(options.state.sortBy) && options.state.sortBy.length > 0) {
        sortBy = options.state.sortBy[0];
      }
      return (
        <CustomHeader
          sorted={sortBy.id === options.column.id}
          sortDesc={sortBy.desc}
          header={cellContent}
          tooltip={tooltip}
        />
      );
    };
  }
  return cellContent;
};

export const createShortInvestorIdentity = (investor, investment) => {
  const params = {
    title: investor?.title,
    surname: investor?.surname,
    name: investor?.name,
    companyName: investor?.company_name,
    siren: investor?.siren,
    coTitle: null,
    coSurname: null,
    coName: null,
  };
  if (investment?.portfolio_type_multiple && investment?.investors?.length > 1) {
    params.title = investment.investors[0].title;
    params.surname = investment.investors[0].surname;
    params.name = investment.investors[0].name;
    params.coTitle = investment.investors[1].title;
    params.coSurname = investment.investors[1].surname;
    params.coName = investment.investors[1].name;
  }
  if (!params.title) {
    // if no title, investor might not be defined yet
    return '';
  }
  let identityKey = 'identity.mono_investor.short_%title_%surname_%name';
  if (utils.users.isLegalEntity(params.title)) {
    identityKey = 'identity.legal_person.short_%companyName_%siren_%surname_%name';
  } else if (investment.portfolio_type_multiple && investment.investors?.length > 1) {
    identityKey = 'identity.investors.short_%title_%surname_%name_%coTitle_%coSurname_%coName';
  }
  return intl.formatMessage({ id: identityKey }, params);
};

export const createOfInvestorIdentity = (investor, investment) => {
  const params = {
    title: investor?.title,
    surname: investor?.surname,
    name: investor?.name,
    companyName: investor?.company_name,
    siren: investor?.siren,
    coTitle: null,
    coSurname: null,
    coName: null,
  };
  if (investment?.portfolio_type_multiple && investment?.investors?.length > 1) {
    params.title = investment.investors[0].title;
    params.surname = investment.investors[0].surname;
    params.name = investment.investors[0].name;
    params.coTitle = investment.investors[1].title;
    params.coSurname = investment.investors[1].surname;
    params.coName = investment.investors[1].name;
  }
  if (!params.title) {
    // if no title, investor might not be defined yet
    return '';
  }
  let identityKey = 'identity.mono_investor.of_%title_%surname_%name';
  if (utils.users.isLegalEntity(params.title)) {
    identityKey = 'identity.legal_person.of_%companyName_%siren_%surname_%name';
  } else if (investment.portfolio_type_multiple && investment.investors?.length > 1) {
    identityKey = 'identity.investors.of_%title_%surname_%name_%coTitle_%coSurname_%coName';
  }
  return intl.formatMessage({ id: identityKey }, params);
};
