import React from 'react';
import Big from 'big.js';
import { Enum } from '@utocat/catalizr-const-sharing-lib';
import { change } from 'redux-form';
import _ from 'lodash';

import UtoLoader from '../../components/Loader';
import Alert from '../../components/Alert';

import { getSubdomain } from './helper';

// -----------------------------------------------------------------------------------------------
// INVEST-DETAILS
// -----------------------------------------------------------------------------------------------

export const INVEST_DETAILS_FORM_NAME = 'invest-details-form';
export const INVESTMENTS_BULK_FORM_NAME = 'investments-bulk-form';

// Object listing names of the objects used into the invest-details redux-form
export const INVEST_DETAILS_REDUX_OBJECTS_NAMING = {
  // Entreprise - Titres apportés
  companyKindProvider: 'companyKindProvider',
  // Entreprise pour l'investissement
  company: 'company',
  // PSFP - Prestataire de services de financement participatif
  financialBroker: 'financialBroker',
  // Acheteur
  investors: 'investors',
  investor: 'investors[0]',
  coInvestor1: 'investors[0]',
  coInvestor2: 'investors[1]',
  // Vendeur
  seller: 'seller',
  // Investment
  investment: 'investment',
  // User
  user: 'user',
  // investmentReglementary datas (investor form confirmation for example)
  investmentReglementary: 'investmentReglementary',
};

/**
 * Function used to dispatch a change action to the "invest-details" redux form.
 * @param {*} fieldKey the key identifying the field in the redux form (ex: account.title)
 * @param {*} fieldValue the value to set.
 * @param {*} dispatch the redux dispatch function.
 */
export const dispatchChangeToInvestDetailsForm = (fieldKey, fieldValue, dispatch) => {
  dispatchChangeToReduxForm(INVEST_DETAILS_FORM_NAME, fieldKey, fieldValue, dispatch);
};

/**
 * Function used to return a target property values (or all) from a redux object name array into a redux form.
 * @param {string} reduxObjectName the key identifying into the redux form (ex: investors[0])
 * @param {object} formValues the form object from redux form
 * @param {string} propName the target property element among the redux object name array
 * @returns {Array} values of the target property name from redux object name array
 */

export const checkReduxObjectNameArrayValues = (reduxObjectName, formValues, propName = null) => {
  const reduxObjectPropName = reduxObjectName?.split('[')[0] || '';
  if (!formValues || !reduxObjectPropName || !reduxObjectName.includes(reduxObjectPropName)) {
    return [];
  }
  const values = _.get(formValues, reduxObjectPropName, []);

  if (!Array.isArray(values)) {
    return [];
  }

  return values
    .map(item => {
      if (propName) {
        const value = _.get(item, propName, undefined);
        return value !== undefined ? { value } : null;
      } else {
        return item;
      }
    })
    .filter(item => item !== null);
};

// -----------------------------------------------------------------------------------------------
// COMMON METHODS
// -----------------------------------------------------------------------------------------------

// Catch keypress ENTER in <form onKeyPress={keypressHandler}>
export const keypressHandler = event => {
  if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
    event.preventDefault();
  }
};

export const formatFloatValue = value => {
  return parseFloat(value.toString().replace(/,/g, '.').replace(/\s/g, ''));
};

export const formatIntValue = value => {
  return parseInt(value.toString().replace(/ /g, '').replace(/\s/g, ''));
};

// -----------------------------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------------------------

/**
 * Function used to dispatch a change action to a redux form.
 * The redux change action allows to update a field of the form.
 * @param {string} reduxForm ID of the redux form to process
 * @param {string} fieldKey the key identifying the field in the redux form (ex: account.title)
 * @param {string} fieldValue the value to set.
 * @param {func} dispatch the redux dispatch function.
 */
export const dispatchChangeToReduxForm = (reduxForm, fieldKey, fieldValue, dispatch) => {
  if (reduxForm && fieldKey && dispatch) {
    dispatch(change(reduxForm, fieldKey, fieldValue ?? ''));
  }
};

/**
 * Manage state on submit form (pending, fulfilled, rejected) to display loader, information message or error message set in common reducer
 * Mainly used on modal
 * @param {object} statusManager required : object containing isLoading (bool), errorMessage and informationMessage (string)
 * @param {string} margin optional : string provided if we want to apply margin
 * @returns JSX div
 */
export const displayErrorOrSuccessMessageAfterSubmit = (statusManager, margin = '') => {
  const { errorMessage, informationMessage, isLoading } = statusManager;
  let modalFooter;
  if (errorMessage) {
    modalFooter = (
      <Alert severity={'error'} center>
        {errorMessage}
      </Alert>
    );
  } else if (informationMessage) {
    modalFooter = (
      <Alert severity={'success'} center>
        {informationMessage}
      </Alert>
    );
  } else if (isLoading) {
    modalFooter = (
      <div className={`modal__error ${margin}`}>
        Envoi en cours...
        <UtoLoader />
      </div>
    );
  }
  return modalFooter;
};

export const checkFormMetadataDisabled = (sectionName, fieldName, formMetadata) => {
  if (formMetadata && formMetadata.length > 0) {
    const filteredMetadata = formMetadata.filter(
      predicate => predicate.field_name === fieldName && predicate.section_name === sectionName,
    );
    if (filteredMetadata.length > 0) {
      return filteredMetadata[0].metadata.disabled;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getTotalAmount = props => {
  const nbPart = formatIntValue(props?.formValues?.investment?.nb_part ?? 0);
  const partAmount = formatFloatValue(props?.formValues?.investment?.part_amount ?? 0);
  const distributionFeeRate = formatFloatValue(
    props?.formValues?.investment?.distribution_fee ?? 0,
  );
  const crowdfundingFees = formatFloatValue(
    props?.formValues?.financialBroker?.crowdfundingFees ?? 0,
  );
  const isFromCrowdFunding = props?.formValues?.financialBroker?.isFromCrowdFunding;
  return getTotalAmountWithFees(
    nbPart,
    partAmount,
    distributionFeeRate,
    isFromCrowdFunding,
    crowdfundingFees,
  );
};

export const getTotalAmountWithFees = (
  nbPart,
  partAmount,
  distributionFeeRate,
  isFromCrowdFunding,
  crowdfundingFees,
) => {
  const securityAmount = Big(nbPart ?? 0).times(partAmount ?? 0);
  let bigTotal = Big(securityAmount);
  if (getSubdomain() === 'phitrust') {
    // Phitrust - 1% de commission pour Fonds de dotation Phitrust (dans LE)
    const bigCommission = securityAmount.times(1).div(100);
    bigTotal = bigTotal.add(bigCommission);
  }
  if (distributionFeeRate) {
    // 1% de commission pour le fonds Phitrust partners
    const bigDistributionFees = securityAmount.times(distributionFeeRate ?? 0).div(100);
    bigTotal = bigTotal.add(bigDistributionFees);
  }
  if (isFromCrowdFunding === Enum.PDF.FieldOptionValue.TRUE) {
    bigTotal = bigTotal.add(crowdfundingFees ?? 0);
  }
  return Big(bigTotal.toFixed(2)).toNumber();
};

export const isNotEmpty = fieldValue =>
  (!!fieldValue && fieldValue !== null && fieldValue !== '' && fieldValue.length > 0) ||
  typeof fieldValue === 'boolean';

export const isNotEmptyDate = fieldValue =>
  !!fieldValue && fieldValue !== null && fieldValue !== '' && typeof fieldValue === 'object';

export const isNewSiren = (formValues, reduxObjectName) =>
  formValues &&
  formValues[reduxObjectName] &&
  isNotEmpty(formValues[reduxObjectName].siren) &&
  !formValues[reduxObjectName].data_from_insee;
