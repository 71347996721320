export const RESET_STATE = 'INVESTOR_RESET_STATE';
export const SET_INVESTOR_EMAIL = 'INVESTOR_SET_EMAIL';
export const INIT_INVESTOR = 'INVESTOR_INIT_INVESTOR';
export const RESET_INVESTOR_ON_EMAIL_DOES_NOT_EXIST = 'RESET_INVESTOR_ON_EMAIL_DOES_NOT_EXIST';

// -------------------------------------------------------------------------
// Available values for is_a_corporation field
// null => user can freely choose the investor civility (all options)
// 0 => investor is a verified natural person (only natural person options)
// 1 => investor is a verified legal person (only legal person options)
// -------------------------------------------------------------------------

export const CIVILITY_FREE_CHOICE = null;
export const CIVILITY_LEGAL_PERSON_LOCKED = 1;

// INVESTOR CATEGORIZATION
export const INVESTOR_WISHING_CLASSIFIED_PROFESSIONAL = 0;
export const INVESTOR_WISHING_CLASSIFIED_NON_PROFESSIONAL = 1;
export const INVESTOR_HOLDING_ENOUGH_FINANCIAL_INSTRUMENT_PORTFOLIO = 0;
export const INVESTOR_HOLDING_ENOUGH_FINANCIAL_INSTRUMENT = 1;
export const INVESTOR_HAS_WORKED_ENOUGH_IN_AN_INVESTMENT_POSITION = 2;

// INVESTOR STATUSES
export const INVESTOR_PROFESSIONAL_WITHIN_L_533_16_AND_D_533_11 = 0;
export const TO_THE_MANAGER_SERVICES_RELATED_TO_MANAGEMENT_OF_FUND = 1;
export const INVESTOR_WHOSE_INITIAL_SUBSCRIPTION_AT_LEAST_HUNDRED_THOUSAND_EUROS = 2;
export const INVESTOR_SUBSCRIPTION_ACQUISITION_CARRIED_OUT_IN_HIS_NAME_AND_ON_HIS_BEHALF = 3;
